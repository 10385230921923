<template>
    <div v-if="project">
        <div class="row">
            <div class="col-sm-22">
                <h3>
                    Detalii nota de debit
                    <a href="#" @click.prevent="openUrl('/reportings/audit/export/'+reporting.id, true)" title="Export in Excel" class="btn btn-sm btn-secondary ml-5">
                        <i class="icofont-file-excel"></i> Export în Excel
                    </a>
                </h3>
                <div class="alert alert-warning">
                    Verificați cu atenție toate informațiile introduse. Pentru orice modificare întoarceți-vă la pasul respectiv și modificați corespunzător.
                </div>
            </div>
        </div>
        <div class="row mt-3" v-for="activity in project.activities">
            <div class="col-sm-12">
                <h6>Activitatea {{ activity.id}}: {{ activity.name }}</h6>
                <el-table size="mini"
                          :data="verifyActivities[activity.id]"
                          style="width: 100%" :summary-method="getActivitySummaries" show-summary>
                    <el-table-column type="index" width="80" align="center" label="Nr. crt."></el-table-column>
                    <el-table-column show-overflow-tooltip prop="category.value" label="Capitol de buget" width="200"></el-table-column>
                    <el-table-column show-overflow-tooltip label="Aplicant/Partener" width="130">
                        <template slot-scope="scope">
                            <span v-if="scope.row.partner_id === 0">Aplicant</span>
                            <span v-if="scope.row.partner_id > 0">{{ scope.row.partner.name }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column show-overflow-tooltip prop="budget.value" label="Linie buget" width="150"></el-table-column>
                    <el-table-column label="Pozitia">
                        <template slot-scope="scope">
                            <span v-if="scope.row.position">{{ scope.row.position.position }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="Nume prenume">
                        <template slot-scope="scope">
                            <span v-if="scope.row.supplier_name">{{ scope.row.supplier_name }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="Motivarea neelig. chelt.">
                        <template slot-scope="scope" v-if="scope.row.description">
                            {{ scope.row.description }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="total_financing_spent" label="Chelt din fin." align="right"  header-align="center" width="100">
                        <template slot-scope="scope">
                            {{ formatPrice(scope.row.total_financing_spent) }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="total_cofinancing_spent" label="Chelt din co-fin." align="right"  header-align="center" width="100">
                        <template slot-scope="scope">
                            {{ formatPrice(scope.row.total_cofinancing_spent) }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="total_spent" label="Cost total" align="right"  header-align="center" width="100">
                        <template slot-scope="scope">
                            {{ formatPrice(scope.row.total_spent) }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="dezv" label="Cost dezv." align="right"  header-align="center" width="100">
                        <template slot-scope="scope">
                            {{ formatPrice(scope.row.dezv) }}
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-sm-12" v-if="verifyIndirects">
                <h6>Cheltuieli indirecte</h6>
                <el-table size="mini" :data="verifyIndirects" :summary-method="getIndirectSummaries" show-summary>
                    <el-table-column type="index" width="80" align="center" label="Nr. crt."></el-table-column>
                    <el-table-column show-overflow-tooltip prop="category.value" label="Capitol de buget" width="200"></el-table-column>
                    <el-table-column prop="partner_id" label="Aplicant/Partener" width="130">
                        <template slot-scope="scope">
                            <span v-if="scope.row.partner_id === 0">{{ scope.row.name }}</span>
                            <span v-if="scope.row.partner_id > 0">P{{ scope.row.partner_id }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column show-overflow-tooltip prop="budget.value" label="Linia de buget" width="150"></el-table-column>
<!--                    <el-table-column label="Furnizor">-->
<!--                        <template slot-scope="scope">-->
<!--                            <span v-if="scope.row.supplier_name">{{ scope.row.supplier_name }}</span>-->
<!--                        </template>-->
<!--                    </el-table-column>-->
                    <el-table-column label="Motivarea neelig. chelt.">
                        <template slot-scope="scope" v-if="scope.row.description">
                            {{ scope.row.description }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="total_financing_spent" label="Chelt din fin." align="right"  header-align="center" width="100">
                        <template slot-scope="scope">
                            {{ formatPrice(scope.row.total_financing_spent) }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="total_cofinancing_spent" label="Chelt din co-fin." align="right"  header-align="center" width="100">
                        <template slot-scope="scope">
                            {{ formatPrice(scope.row.total_cofinancing_spent) }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="total_spent" label="Cost total" align="right"  header-align="center" width="100">
                        <template slot-scope="scope">
                            {{ formatPrice(scope.row.total_spent) }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="dezv" label="Cost dezv." align="right"  header-align="center" width="100">
                        <template slot-scope="scope">
                            {{ formatPrice(scope.row.dezv) }}
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapGetters, mapActions, mapMutations } from 'vuex'
    import mixin from './../../mixins/common';

    export default {
        name: "reporting-verify-form",
        mixins:[mixin],
        props:{
            verifyActivities:[],
            verifyIndirects:[],
        },
        computed:{
            ...mapGetters({
                project: 'project/project',
                reporting: 'reportings/reporting',
                validationErrorsExists: 'validationErrorsExists',
            }),
        },
        methods:{
            getActivitySummaries(param) {
                const { columns, data } = param;
                const sums = [];
                columns.forEach((column, index) => {
                    if (index === 0) {
                        sums[index] = 'Totaluri';
                        return;
                    }
                    if(index > 0 && index < 7){
                        sums[index] = '';
                        return;
                    }
                    const values = data.map(item => Number(item[column.property]));
                    if (!values.every(value => isNaN(value))) {
                        sums[index] = values.reduce((prev, curr) => {
                            const value = Number(curr);
                            if (!isNaN(value)) {
                                return prev + curr;
                            } else {
                                return prev;
                            }
                        }, 0);
                    } else {
                        sums[index] = ' - ';
                    }
                    if(index > 6){
                        sums[index] =  (sums[index]/1).toFixed(2).replace('.', ',') + '€';
                    }
                });
                return sums;
            },
            getIndirectSummaries(param) {
                const { columns, data } = param;
                const sums = [];
                columns.forEach((column, index) => {
                    if (index === 0) {
                        sums[index] = 'Totaluri';
                        return;
                    }
                    if(index > 0 && index < 5){
                        sums[index] = '';
                        return;
                    }
                    const values = data.map(item => Number(item[column.property]));
                    if (!values.every(value => isNaN(value))) {
                        sums[index] = values.reduce((prev, curr) => {
                            const value = Number(curr);
                            if (!isNaN(value)) {
                                return prev + curr;
                            } else {
                                return prev;
                            }
                        }, 0);
                    } else {
                        sums[index] = ' - ';
                    }
                    if(index > 4){
                        sums[index] =  (sums[index]/1).toFixed(2).replace('.', ',') + '€';
                    }
                });
                return sums;
            }
        }
    }
</script>

<style scoped>

</style>
