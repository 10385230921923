<template>
    <div>
        <div class="row">
            <div class="col-sm-12">
                <h3>Documente</h3>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-sm-12" id="reportingDocuments">
                <h6>Încărcare documente</h6>
                <el-table :data="auditDocuments.filter(d => { return parseInt(d.category_id) !== 590 })" v-if="auditDocuments"
                          ref="documentTable" id="documentTable" stripe fit highlight-current-row style="width:100%">
                    <el-table-column type="index"></el-table-column>
                    <el-table-column label="Denumire" prop="name"></el-table-column>
                    <el-table-column label="Fișier" prop="file">
                        <template slot-scope="scope">
                            <template v-if="scope.row.id">
                                <el-link icon="el-icon-view" :href="'/getFile/' + scope.row.id"
                                         target="_blank">Vezi fișierul 1</el-link>
                            </template>
                            <template v-if="scope.row.response">
                                <el-link icon="el-icon-view"
                                         :href="'/getFile/'+scope.row.response.file_id" target="_blank">Vezi fișierul 2</el-link>
                            </template>
                        </template>
                    </el-table-column>
                    <el-table-column label="Acțiuni">
                        <template slot-scope="scope">
                            <el-button size="mini" type="secondary" icon="el-icon-edit"
                                       @click="handleRowSelect(scope.row)"></el-button>
                            <el-button size="mini" type="danger" icon="el-icon-delete"
                                       @click="handleRowDelete(scope.$index, scope.row)"></el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <el-form :model="form" id="documentForm" :rules="rules" ref="documents" label-position="top" :status-icon="true" :inline-message="true">
                    <div class="row">
                        <div class="col-sm-12">
                            <h4 class="mb-4 mt-5">Adaugă/modifică document</h4>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <el-form-item label="Denumire" prop="name">
                                    <el-input v-model="form.name" placeholder="Nume document" clearable
                                              type="text" maxlength="60" size="large" show-word-limit>
                                    </el-input>
                                </el-form-item>
                            </div>
                        </div>
                        <div class="col-sm-6 form-group">
                            <el-form-item label="Fișier" prop="file">
                                <span v-if="!form.name"><i class="el-icon-warning-outline"></i> Introdu numele pentru a încărca documentul</span>
                                <el-button v-if="form.name" @click.prevent="uploadFile(form)">
                                    <span v-if="form.file">Modificare fișier</span>
                                    <span v-else>Încarcă fișierul</span>
                                </el-button>
                            </el-form-item>
                        </div>
                        <div class="col-sm-12 text-right mb-5"><!--  v-if="form.file"-->
                            <el-button @click.prevent="clear">Golește formularul</el-button>
                            <el-button @click.prevent="addDocument" type="secondary">Aplică modificările</el-button>
                        </div>
                    </div>
                </el-form>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapGetters, mapActions, mapMutations } from 'vuex'
    import mixin from './../../mixins/common';
    import VueScrollTo from "vue-scrollto";

    export default {
        name: "reporting-documents",
        mixins:[mixin],
        data(){
            return {
                csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
                form:{
                },
                rules: {
                    name: [
                        {required: true, message: 'Denumirea este obligatorie', trigger: 'blur'},
                        { min: 3, max: 60, message: 'Lungimea trebuie sa fie intre 3 si 60 caractere', trigger: 'blur' }
                    ],
                },
            }
        },
        computed:{
            ...mapGetters({
                auditDocuments: 'reportings/auditDocuments',
                validateDocuments: 'reportings/validateDocuments',
                validationErrorsExists: 'validationErrorsExists',
                reporting: 'reportings/reporting',
                showUploadDrawer: 'showUploadDrawer',
            }),
        },
        watch:{
            validateDocuments: function(){
                if(this.validateDocuments) {
                    this.submitForm();
                }
            },
            showUploadDrawer: function(a){
                if(this.showUploadDrawer.visible === false){
                    if(this.showUploadDrawer.files && this.showUploadDrawer.files.length > 0 && this.showUploadDrawer.files[0].response){
                        this.form.id = this.showUploadDrawer.files[0].response.file_id;
                    }
                }
                return this.showUploadDrawer;
            },
        },
        methods:{
            ...mapMutations({
                setValidateDocuments: 'project/setValidateDocuments',
                setValidationErrorsExists: 'setValidationErrorsExists',
                setShowUploadDrawer: 'setShowUploadDrawer',
            }),
            ...mapActions({
                removeDocument: 'removeUploadedFile',
                getAuditDocuments: 'reportings/getAuditDocuments',
                saveAuditDocument: 'reportings/saveAuditDocument',
            }),
            uploadFile(document){
                this.setShowUploadDrawer({
                    visible: true,
                    model: 'App\\Models\\Reporting',
                    model_id: this.reporting.id,
                    category_id: 555,
                    acceptFiles: '.pdf,.docx,.doc,.xlsx,.xls',
                    name: document.name,
                    multiple: false,
                    object: document,
                    title: 'Încărcare document',
                    files: document.file ? [document] : [],
                    // reportingDocument: 1,
                    autoClose: true,
                });
            },
            // refreshData(){
            //     if(this.reporting.project_id && this.reporting.id){
            //         this.getReportingDocuments({project_id: this.reporting.project_id, reporting_id: this.reporting.id})
            //         .then(result => {
            //                 if(result.length === 0) {
            //                     this.saveReportingDocument({
            //                         project_id: this.reporting.project_id,
            //                         reporting_id: this.reporting.id
            //                     }).then(response => {
            //                         this.setReportingDocuments(response);
            //                     });
            //                 }
            //         })
            //     }
            // },
            addDocument(){
                this.$refs.documents.validate((valid) => {
                    if (valid) {
                        if(this.form.index > this.auditDocuments.length) {
                            this.auditDocuments.push(this.form);
                        }else{
                            this.auditDocuments[this.form.index - 1] = this.form;
                        }
                        this.saveAuditDocument({
                            reporting_id: this.reporting.id,
                            document: this.form
                        });
                        // this.getAuditDocuments(this.reporting.id);
                        this.clear();
                        this.setValidateDocuments(false);
                        return true;
                    } else {
                        return false;
                    }
                });
                VueScrollTo.scrollTo('#documentTable');
            },
            // saveDocumentData(){
            //     this.saveProject(this.project).then((result) => {
            //         if(result.valid === false){
            //             this.$notify({ type:'error', title: 'Eroare', message: result.message });
            //             reject(false);
            //         }
            //     });
            // },
//             handleUploadRemove(file, fileList){
// console.log(file, fileList);
//                 this.form.file_id = '';
//                 this.$refs.documents.validateField('file')
//             },
//             handleUploadSuccess (res, file, fileList) {
//                 // if(file.status === 'success'){
//                 //     file.response.success = file.response.success.replace("public","storage");
//                 // }
// console.log(res, file, fileList);
//                 this.form.file = file.file;
//                 this.$refs.documents.validateField('file')
//             },
//             handleUploadChange(file, fileList) {
// console.log(file, fileList);
//                 this.fileList = fileList.slice(-3);
//             },
            handleRowSelect(row){
                this.form = row;
                VueScrollTo.scrollTo('#documentForm');
            },
            handleRowDelete(index, row){
                this.$confirm('Esti sigur(a) ca vrei sa stergi aceasta inregistrare?')
                    .then(_ => {
                        if (index !== -1) {
                            if(row.id > 0) {
                                // console.log(row);
                                this.removeDocument(row.id)
                                    .then(_ => {
                                        this.getAuditDocuments({ reporting_id: this.reporting.id });
                                    });
                                this.auditDocuments.splice(index, 1);
                            }else {
                                this.auditDocuments.splice(index, 1);
                            }
                            this.$notify({ type: 'success', title: 'Documente', message: 'Înregistrarea a fost ștearsă cu succes!'});

                            this.clear();
                        }
                    })
                    .catch(err => {
                        console.log(err)
                    });
            },
            clear(){
                // this.$refs.upload.clearFiles();
                this.form = {
                    index: (this.reporting.documents ? this.reporting.documents.length : 0) + 1,
                    name: null,
                    file: null
                };
            },

            submitForm() {
                // if (this.reporting.documents.length > 0) {
                this.setValidateDocuments(false);
                this.setValidationErrorsExists(false);
                return true;
                // }
                // else {
                // this.setValidationErrorsExists(true);
                // this.$notify({ type: 'warning', title: 'Documente proiect', message: 'Trebuie sa adaugati cel putin un document!'});
                // return false;
                // }
            },
        }
    }
</script>

<style scoped>

</style>
