<template>
    <div>
        <div class="row mb-3">
            <div class="col-xs-12 col-sm-6">
                <small style="font-size:12px;">Apasa <i class="icofont-keyboard"></i> ENTER pentru a efectua o cautare in baza de date!</small>
                <el-input v-model="search" placeholder="Cauta proiect dupa: ID(#)/Denumire (RO/ENG)/Promotor" prefix-icon="el-icon-search" @change="getInfo()" clearable />
            </div>
<!--            <el-button type="secondary" size="small" @click="uploadXML()"><i class="icofont-plus"></i> Import din XML</el-button>-->
        </div>
        <div class="row">
            <div class="col-sm-12"> <!-- .filter(data => !search || data.name.toLowerCase().includes(search.toLowerCase()) || data.name_en.toLowerCase().includes(search.toLowerCase()) || data.promoter.name.toLowerCase().includes(search.toLowerCase()) || data.funding_number.toLowerCase().includes(search.toLowerCase())) -->
                <el-table :data="projects"
                         style="width: 100%" fit highlight-current-row v-loading="loading" size="small">
                    <!-- <el-table-column type="expand">
                        <template slot-scope="props">
                            <div class="row">
                                <div class="col-sm-4">
                                    <p>
                                        <label class="label-left">Denumire:</label> <label>{{ props.row.name }} | {{ props.row.name_en }}</label><br />
                                        <label class="label-left">Runda:</label> <label>{{ props.row.round ? props.row.round.value : '' }}</label><br />
                                        <label class="label-left">Apel:</label> <label>{{ props.row.appeal ? props.row.appeal.value : '' }}</label><br />
                                        <label class="label-left">Grant:</label> <label>{{ props.row.grant ? props.row.grant.value : '' }}</label><br />
                                        <label class="label-left">Promotor:</label> <label>{{ props.row.promoter.name ? props.row.promoter.name : '' }}</label><br />
                                    </p>
                                </div>
                                <div class="col-sm-4">
                                    <p>
                                        <label class="label-left">Buget total:</label> <label>{{ props.row.total_budget ? formatPrice(props.row.total_budget) : '' }}</label><br />
                                        <label class="label-left">Buget finantat:</label> <label>{{ props.row.funding_budget ? formatPrice(props.row.funding_budget) : '' }}</label><br />
                                        <label class="label-left">Buget co-finantat:</label> <label>{{ props.row.cofunding_budget ? formatPrice(props.row.cofunding_budget) : '' }}</label><br />
                                        <label class="label-left">Buget indirect:</label> <label>{{ props.row.indirect_budget ? formatPrice(props.row.indirect_budget) : '' }}</label><br />
                                    </p>
                                </div>
                                <div class="col-sm-4">
                                    <p>
                                        <label class="label-left">Data contract:</label> <label>{{ props.row.contract_date ? formatUserDate(props.row.contract_date) : '' }}</label><br />
                                        <label class="label-left">Data incepere implementare:</label> <label>{{ props.row.project_start_date ? formatUserDate(props.row.project_start_date) : '' }}</label><br />
                                        <label class="label-left">Data finalizare implementare:</label> <label>{{ props.row.project_end_date ? formatUserDate(props.row.project_end_date) : '' }}</label><br />
                                        <label class="label-left">Creat la:</label> <label>{{ props.row.created_at ? formatUserDateTime(props.row.created_at) : '' }}</label>
                                    </p>
                                </div>
                            </div>
                        </template>
                    </el-table-column>-->
                    <el-table-column prop="funding_number" label="#" width="70" align="right"></el-table-column>
                    <el-table-column prop="round.value" label="Runda | Apel | Grant" width="140">
                        <template slot-scope="scope">
                            <span v-if="scope.row.round">{{ scope.row.round.value }}</span>
                            <span v-if="scope.row.appeal">{{ scope.row.appeal.value }}</span><br />
                            <span v-if="scope.row.grant">{{ scope.row.grant.value }}</span>
                        </template>
                    </el-table-column>
<!--                    <el-table-column prop="appeal.value" label="Apel" width="80" sortable></el-table-column>-->
<!--                    <el-table-column prop="grant.value" label="Grant" width="110" sortable></el-table-column>-->
                    <el-table-column prop="name" label="Denumire" sortable min-width="200">
                        <template slot-scope="scope">
                            <el-tooltip effect="light" placement="top-start">
                                <div slot="content">{{ scope.row.name }}</div>
                                <a href="#" @click.prevent="openUrl('/projects/show/' + scope.row.id)">{{ scope.row.name }}</a>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column prop="promoter.name" label="Promotor" sortable width="150">
                        <template slot-scope="scope">
                            <el-tooltip v-if="scope.row.promoter" effect="light" :content="scope.row.promoter.name" placement="top-start">
                                <div>{{ scope.row.promoter.name | truncate(25, '...') }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="Parteneri" width="80" align="center" header-align="center">
                        <template slot-scope="scope">
                            <el-popover placement="right-start" width="380" trigger="click" v-if="scope.row.partners && scope.row.partners.length > 0">
                                <el-table :data="daPA" v-if="daPA.length > 0" style="width:100%" size="small" v-loading="popLoading">
                                    <el-table-column width="200" property="name" label="Partener">
                                        <template slot-scope="prscope">{{ prscope.row.name }}</template>
                                    </el-table-column>
                                    <el-table-column width="150" label="Buget" align="right" header-align="center">
                                        <template slot-scope="prscope">
                                            <comp-partner-budget :amounts="prscope.row.budget"></comp-partner-budget>
                                        </template>
                                    </el-table-column>
                                </el-table>

                                <el-button @click="swapComponent(scope.row.id, scope.row.partners)" round size="small" slot="reference">{{ scope.row.partners.length }}</el-button>
                            </el-popover>
                        </template>
                    </el-table-column>
                    <el-table-column label="Buget" header-align="center">
                        <el-table-column prop="total_budget" label="Total" sortable align="right" header-align="center" width="110">
                            <template slot-scope="scope">
                                <span>{{ formatPrice(scope.row.total_budget,2) }}</span><br />
                            </template>
                        </el-table-column>
                        <el-table-column prop="total_budget" label="Finantat" sortable align="right" header-align="center" width="110">
                            <template slot-scope="scope">
                                <span>{{ formatPrice(scope.row.funding_budget,2) }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="total_budget" label="Co-finantat" sortable align="right" header-align="center" width="110">
                            <template slot-scope="scope">
                                <span>{{ formatPrice(scope.row.cofunding_budget,2) }}</span>
                            </template>
                        </el-table-column>
                    </el-table-column>
                    <el-table-column label="Responsabili" header-align="center" width="150">
                        <template slot-scope="scope">
                            <div v-for="user in scope.row.users">
                                {{ user.first_name }} {{ user.last_name }}
                                <span v-if="user.all_roles"> - ({{ user.all_roles[0].display_name}})</span>
                            </div>
                        </template>
                    </el-table-column>
                    <!--

                    Scos pentru ca se incurca cu cele existente, fiind prea multe datele din tabel si diagonala prea mica.
                    Aceste informatii sunt in continuare afisate atunci cand proiectul este deschis din tabel. (Click pe EL)
                    Se poate readauga daca se considera necesar.

                    <el-table-column label="Date" header-align="center">
                        <el-table-column prop="contract_date" label="Contract" align="right" header-align="center" width="100" sortable>
                            <template slot-scope="scope">
                                <span>{{ formatUserDate(scope.row.contract_date) }}</span><br />
                            </template>
                        </el-table-column>
                        <el-table-column prop="contract_date" label="Incepere" align="right" header-align="center" width="100" sortable>
                            <template slot-scope="scope">
                                <span>{{ formatUserDate(scope.row.project_start_date) }}</span><br />
                            </template>
                        </el-table-column>
                        <el-table-column prop="project_end_date" label="Finalizare" align="right" header-align="center" width="100" sortable>
                            <template slot-scope="scope">
                                <span>{{ formatUserDate(scope.row.project_end_date) }}</span>
                            </template>
                        </el-table-column>
                    </el-table-column>-->

                    <el-table-column label="Stare" align="center" header-align="center" width="80" fixed="right">
                        <template slot-scope="scope">
<!--                            {{scope.row.status_technical}} {{scope.row.status_financial}}-->
                            <span>T:</span>
                            <span v-if="scope.row.status_technical === 1" class="badge" style="background-color:#FDD9B5;">Ciorna</span>
                            <span v-else-if="scope.row.status_technical === 2" class="badge" style="background-color:#FDD9B5;">Ciorna</span>
                            <span v-else-if="scope.row.status_technical === 3" class="badge" style="background-color:#FABE86;">Activitati</span>
                            <span v-else-if="scope.row.status_technical === 4" class="badge" style="background-color:#ED8E30;">Documente</span>
                            <span v-else-if="scope.row.status_technical === 5" class="badge" style="background-color:#D77200">Parteneri</span>
                            <span v-else-if="scope.row.status_technical === 6" class="badge" style="background-color:#B65900">Obiective</span>
                            <span v-else-if="scope.row.status_technical === 7" class="badge" style="background-color:#924400">Indicatori</span>
                            <span v-else-if="scope.row.status_technical === 8" class="badge" style="background-color:#663000">Calendar</span>
                            <span v-else-if="scope.row.status_technical === 9" class="badge" style="background-color:#006F06">Complet</span>
                            <br />
                            <span>F:</span>
                            <span v-if="scope.row.status_financial === 1" class="badge" style="background-color:#B99ABF">Echipa</span>
                            <span v-else-if="scope.row.status_financial === 2" class="badge" style="background-color:#AE90C2">Bugete</span>
                            <span v-else-if="scope.row.status_financial === 3" class="badge" style="background-color:#5F3D68">Indirecte</span>
                            <span v-else-if="scope.row.status_financial === 4" class="badge" style="background-color:#5F3D68">Finantare</span>
                            <span v-else-if="scope.row.status_financial === 9" class="badge" style="background-color:#006F06">Complet</span>
                        </template>
                    </el-table-column>
                    <el-table-column align="right" width="70" fixed="right" v-if="checkPermission(['project-technical-write']) || checkPermission(['project-financial-write'])">
                        <template slot-scope="scope">
                            <el-dropdown @command="handleCommand" trigger="click">
                                <span class="el-dropdown-link">
                                    <i class="el-icon-s-operation"></i> <i class="el-icon-arrow-down el-icon--right"></i>
                                </span>
                                <el-dropdown-menu slot="dropdown">
                                    <template v-if="scope.row.status_financial !== 9 || scope.row.status_technical !== 9">
                                        <el-dropdown-item :command="[scope.row.id,0]" v-if="checkPermission(['project-technical-write'])">Date identificare</el-dropdown-item>
                                        <el-dropdown-item :command="[scope.row.id,1]" v-if="scope.row.status_technical >= 1 && checkPermission(['project-technical-write'])">Descrieri</el-dropdown-item>
                                        <el-dropdown-item :command="[scope.row.id,2]" v-if="scope.row.status_technical >= 2 && checkPermission(['project-technical-write'])">Activitati</el-dropdown-item>
                                        <el-dropdown-item :command="[scope.row.id,3]" v-if="scope.row.status_technical >= 3 && checkPermission(['project-technical-write'])">Documente</el-dropdown-item>
                                        <el-dropdown-item :command="[scope.row.id,4]" v-if="scope.row.status_technical >= 4 && checkPermission(['project-technical-write'])">Parteneri</el-dropdown-item>
                                        <el-dropdown-item :command="[scope.row.id,5]" v-if="scope.row.status_technical >= 5 && checkPermission(['project-technical-write'])">Obiective</el-dropdown-item>
                                        <el-dropdown-item :command="[scope.row.id,6]" v-if="scope.row.status_technical >= 6 && checkPermission(['project-technical-write'])">Indicatori</el-dropdown-item>
                                        <el-dropdown-item :command="[scope.row.id,7]" v-if="scope.row.status_technical >= 7 && checkPermission(['project-technical-write'])">Calendar activitati</el-dropdown-item>
                                        <el-dropdown-item :command="[scope.row.id,10]" v-if="(scope.row.status_financial >= 1 || scope.row.status_technical >= 3) && checkPermission(['project-financial-write'])">Echipa</el-dropdown-item>
                                        <el-dropdown-item :command="[scope.row.id,11]" v-if="scope.row.status_financial >= 2 && checkPermission(['project-financial-write'])">Bugete</el-dropdown-item>
                                        <el-dropdown-item :command="[scope.row.id,40]" v-if="scope.row.status_financial >= 3 && checkPermission(['project-financial-write'])">Indirecte</el-dropdown-item>
                                        <el-dropdown-item :command="[scope.row.id,41]" v-if="scope.row.status_financial >= 4 && checkPermission(['project-financial-write'])">Surse finantare</el-dropdown-item>
                                        <!--                                    <el-dropdown-item disabled :command="[scope.row.id,43]" v-if="scope.row.status_financial >= 5">Plati</el-dropdown-item>-->
                                        <el-dropdown-item :command="[scope.row.id, 'delete']" v-if="scope.row.status_technical <= 3 && checkPermission(['project-technical-write'])" divided>Sterge</el-dropdown-item>
                                    </template>
                                    <el-dropdown-item  v-if="checkPermission(['project-user-write'])" :command="[scope.row.id, 'assign']" divided>Aloca utilizator</el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="col-sm-12 text-right">
                <el-pagination layout="prev, pager, next, total" class="bg-white" :hide-on-single-page="true" :page-size="pag_page"
                   :total="pag_total" :current-page="pag_current" @current-change="handleCurrentChange"></el-pagination>
            </div>
        </div>
        <upload-drawer-xml></upload-drawer-xml>
    </div>
</template>

<script>
    import { mapGetters, mapActions, mapMutations } from 'vuex'
    import mixin from '../../mixins/common';
    import UploadDrawer from "../common/upload-drawer-xml";
    import CompPartnerBudget from "./comp-partner-budget";
    export default {
        name: "project-list",
        components: {UploadDrawer,CompPartnerBudget},
        mixins:[mixin],
        props:{
            permissions:{
                required: true,
            },
        },
        data() {
            return {
                loading: true,
                search: '',
                daPA: {},
                pag_total: 0,
                pag_current: 1,
                pag_page: 10,
                popLoading: false,
            }
        },
        computed:{
            ...mapGetters({
                projects: 'project/projects',
            }),
        },
        mounted(){
            this.getData(this.pag_current);
        },
        methods:{
            ...mapActions({
                getPartnerBudget: 'project/getPartnerBudget',
                getProjects: 'project/getProjects',
                findSor: 'project/findSor',
                removeProject: 'project/removeProject',
            }),
            ...mapMutations({
                setShowUploadDrawer: 'setShowUploadDrawer'
            }),
            swapComponent: function(project_id, component){
                this.popLoading = true;
                let data = [];
                component.forEach((value) => {
                    this.getPartnerBudget({project_id: project_id, partner_id: value.id}).then((result)=>{
                        data.push({
                            budget: result,
                            name: value.name
                        })
                        this.popLoading = false;
                    });
                });
                this.daPA  = data;
            },
            getInfo() {
                if (this.search.length > 0) {
                    this.loading = true;
                    this.pag_total = 0;
                    this.findSor(this.search).then((response) => {
                        this.pag_total = response.total;
                        this.loading = false;
                    });
                } else {
                    this.getData(this.pag_current);
                }
            },
            // uploadXML(form) {
            //     this.setShowUploadDrawer({
            //         visible: true,
            //         model: 'App\\Models\\Project',
            //         category_id: 5,
            //         acceptFiles: '.xml',
            //         multiple: false,
            //         title: 'Import proiect din XML',
            //         files: []
            //     });
            // },
            handleCommand(command){
                if(command[1] === 'delete') {
                    this.loading = true;
                    this.$confirm('Esti sigur(a) ca vrei sa stergi aceast proiect? Acest proces este ireversibil!')
                        .then(_ => {
                            this.removeProject({id: command[0]}).then(() => {
                                this.getProjects([this.pag_current,this.pag_page]).then(() => {
                                    this.loading = false;
                                })
                                this.$notify({
                                    type: 'success',
                                    title: 'Stergere date',
                                    message: 'Proiectul a fost sters cu succes!'
                                });
                                this.loading = false;
                            })
                        }).catch(err => {
                        this.loading = false;
                        console.log(err)
                    });
                }else if(command[1] === 'assign'){
                    this.openUrl('/projects/account/' + command[0]);
                }else {
                    this.openUrl('/projects/edit/' + command[0] + '/' + command[1]);
                }
            },
            handleCurrentChange(val){
                this.pag_current = val;
               this.getData(val);
            },
            getData(page){
                this.loading = true;
                this.getProjects([page,this.pag_page]).then((response) => {
                    this.pag_total = parseInt(response.total);
                    this.pag_current = parseInt(response.current_page);
                    this.pag_page = parseInt(response.per_page);
                    this.loading = false;
                });
            },
            loadBudget(row){
                console.log(row);

            },
            checkPermission(permission){
                let perm = this.permissions;
                return _.filter(permission, function (item){
                    return _.includes(perm, item);
                }).length;
            }
        },

    }
</script>

<style scoped>
    .badge {
        color:#ffffff;font-weight:normal;
    }
    .el-dropdown-link{
        cursor:pointer;
    }
</style>
