<template>
    <el-form :model="reporting" v-loading="loading" ref="document" id="document" label-position="top" :status-icon="true" :inline-message="false">
        <div class="row">
            <div class="col-sm-12">
                <h3>Semnare nota de debit</h3>
                <div class="alert alert-info">
                    Exportati documentul .PDF si incarcati-l completat & semnat pentru a finaliza!
                </div>
                <hr>
                <el-button type="primary" :disabled="!reporting.audit_agent" class="float-left" @click="exportDOC()"><i class="icofont-file-pdf"></i> Exporta nota de debit</el-button>
                <el-button type="secondary" :disabled="!reporting.audit_agent" class="float-left" icon="el-icon-edit" @click="uploadDocument()">
                  <span>Incarca nota de debit</span>
                </el-button>
            </div>
        </div>
    </el-form>
</template>

<script>
    import { mapGetters, mapMutations, mapActions } from 'vuex'
    import VueScrollTo from 'vue-scrollto';
    export default {
        name: "reporting-document-form",
        data(){
            return {
                loading: false,
            }
        },
        computed:{
            ...mapGetters({
                reporting: 'reportings/reporting',
                //validateDocument: 'reportings/validateDocument',
                validationErrorsExists: 'validationErrorsExists',
                showUploadDrawer: 'showUploadDrawer',
            }),
        },
        watch:{
            showUploadDrawer: function(a){
                if(this.showUploadDrawer.visible === false){
                   this.getAuditDocuments({ reporting_id: this.reporting.id });
                }
                return this.showUploadDrawer;
            },
        },
        methods:{
            ...mapActions({
                saveReporting: 'reportings/saveReporting',
                getAuditDocuments: 'reportings/getAuditDocuments',
            }),
            ...mapMutations({
                setShowUploadDrawer: 'setShowUploadDrawer',
                //setValidateDocument: 'reportings/setValidateDocument',
                setValidationErrorsExists: 'setValidationErrorsExists',
            }),
            exportDOC() {
                window.open('/reportings/audit/export-pdf-audit/'+this.reporting.id, '_blank');
            },
            uploadDocument() {
                this.setShowUploadDrawer({
                    visible: true,
                    model: 'App\\Models\\Reporting',
                    model_id: this.reporting.id,
                    category_id: 590,
                    acceptFiles: '.pdf',
                    multiple: true,
                    title: 'Vizualizare documente',
                    files: this.reporting.documents.filter((doc) => { return parseInt(doc.category_id) === 590 }),
                    reportingDocument: 1,
                });
            },
            submitForm() {
                console.log('submit');
                this.$refs['document'].validate((valid) => {
                    console.log('valid ' + valid);
                    if (!valid) {
                        //this.setValidateDocument(false);
                        this.setValidationErrorsExists(false);
                        VueScrollTo.scrollTo('#document');
                        return true;
                    } else {
                        VueScrollTo.scrollTo('#document');
                        this.setValidationErrorsExists(true);
                        return false;
                    }
                })
            }
        }
    }
</script>

<style scoped>

</style>
