export default{
    projects: [],
    project: {},
    locations: [],
    appeals: [],
    rounds: [],
    grants: [],
    medium: [],
    framing: [],
    priorities: [],
    prioritiesNew: [],
    policies: [],
    outcomes: [],
    outputs: [],
    outcomeIndicators: [],
    outputIndicators: [],
    companyTypes: [],
    documentCategories: [],
    workloads: [],
    costCategories: [],
    indirectCategories: [],
    budgetLines: [],
    units: [],
    indirects: [],
    users: [],

    validateDetails: false,
    validateDescription: false,
    validateActivities: false,
    validateDocuments: false,
    validatePartners: false,
    validateObjectives: false,
    validateIndicators: false,
    validateCalendar: false,
    validateTeam: false,
    validateActivity: [],
    validateIndirects: false,
    validateFinancing: false,
    validateValidation: false,
    validateUsers: false,
}
