<template>
    <div>
        <el-form :model="form" :rules="rules" ref="reportForm" label-width="120px" label-position="top">
            <el-form-item label="Selecteaza raportul" prop="report">
                <el-select
                    filterable
                    v-model="form.report"
                    value-key="id"
                    style="width: 30%;min-width:400px"
                    @change="clearData"
                >
                    <el-option
                        v-for="rep in reports"
                        :key="rep.id"
                        :label="rep.name"
                        :value="rep"
                    />
                </el-select>
            </el-form-item>
            <div class="row mt-1" v-if="form.report.filters">
<!--                :rules="[-->
<!--                { required: true, message: 'Camp obligatoriu', trigger: 'blur' },-->
<!--                { type: 'email', message: 'Please input correct email address', trigger: ['blur', 'change'] }-->
<!--                ]"-->
                <el-form-item label="Denumire companie" prop="company" class="col-sm-2" v-if="form.report.filters.company">
                    <el-input v-model="form.selectedFilters.company" size="medium"></el-input>
                </el-form-item>
                <el-form-item label="Numar contract de finantare" prop="projectId" class="col-sm-2" v-if="form.report.filters.projectID">
                    <el-input v-model="form.selectedFilters.projectId" size="medium"></el-input>
                </el-form-item>
                <el-form-item label="Functia" prop="position" class="col-sm-2" v-if="form.report.filters.position">
                    <el-input v-model="form.selectedFilters.position" size="medium"></el-input>
                </el-form-item>
                <el-form-item label="Nume/Prenume" prop="name" class="col-sm-2" v-if="form.report.filters.name">
                    <el-input v-model="form.selectedFilters.name" size="medium"></el-input>
                </el-form-item>
                <el-form-item label="Responsabil" prop="responsive" class="col-sm-2" v-if="form.report.filters.responsive">
                    <el-input v-model="form.selectedFilters.responsive" size="medium"></el-input>
                </el-form-item>
                <el-form-item label="Stare" prop="status" class="col-sm-2" v-if="form.report.filters.status">
                    <el-input v-model="form.selectedFilters.status" size="medium"></el-input>
                </el-form-item>
                <el-form-item label="Partener consortiu" prop="fund_operator" class="col-sm-2" v-if="form.report.filters.fund_operator">
                    <el-input v-model="form.selectedFilters.fund_operator" size="medium"></el-input>
                </el-form-item>
                <el-form-item label="CNP" prop="cnp" class="col-sm-2" v-if="form.report.filters.cnp">
                    <el-input v-model="form.selectedFilters.cnp" size="medium"></el-input>
                </el-form-item>
                <el-form-item label="CIM" prop="cim" class="col-sm-2" v-if="form.report.filters.cim">
                    <el-input v-model="form.selectedFilters.cim" size="medium"></el-input>
                </el-form-item>
                <el-form-item label="CIF" prop="cif" class="col-sm-2" v-if="form.report.filters.cif">
                    <el-input v-model="form.selectedFilters.cif" size="medium"></el-input>
                </el-form-item>
                <el-form-item label="De la data" prop="from_date" class="col-sm-2" v-if="form.report.filters.from_date">
                    <el-date-picker v-model="form.selectedFilters.from_date" placeholder="Selecteaza data" type="date" value-format="yyyy-MM-dd"
                                    format="dd.MM.yyyy" size="medium"></el-date-picker>
                </el-form-item>
                <el-form-item label="Pana la data" prop="to_date" class="col-sm-2" v-if="form.report.filters.to_date">
                    <el-date-picker v-model="form.selectedFilters.to_date" placeholder="Selecteaza data" type="date" value-format="yyyy-MM-dd"
                                    format="dd.MM.yyyy" size="medium"></el-date-picker>
                </el-form-item>
                <el-form-item label=" " class="col-sm-4">
                    <el-button @click.prevent="submit" type="primary">Genereaza raport</el-button>
                    <el-button @click.prevent="exportExcel" type="secondary">Export</el-button>
                </el-form-item>
            </div>
        </el-form>
        <hr />
        <el-table v-loading="loading" v-if="reportData.length > 0" :data="reportData" size="mini" empty-text="Selecteaza si ruleaza un raport"
                  height="500" stripe fit highlight-current-row style="width:100%">
            <el-table-column label="Nr Crt" type="index"></el-table-column>
            <el-table-column v-for="col in form.report.columns" :prop="col.prop" :label="col.label" :key="col.prop">
                <template slot-scope="scope">
                    <span v-if="col.format === 'decimal'">{{ formatNumber(scope.row[col.prop], 2) }}</span>
                    <span v-else-if="col.format === 'euro'">{{ formatPrice(scope.row[col.prop], 2, '€') }}</span>
                    <span v-else-if="col.format === 'ron'">{{ formatPrice(scope.row[col.prop], 2, 'Lei') }}</span>
                    <span v-else>{{ scope.row[col.prop] }}</span>
                </template>
            </el-table-column>
        </el-table>
        <div v-else class="alert alert-info" v-loading="loading">Nu exista date de afisat in acest moment</div>
    </div>
</template>
<script>
    import mixin from './../../mixins/common';
    import VueScrollTo from 'vue-scrollto';
    import {mapActions, mapGetters, mapMutations} from 'vuex';
    export default {
        name: "report-page-view",
        mixins:[mixin],
        data(){
            return{
                reports: [
                    {
                        id: 'F0',
                        name: '- - - RAPOARTE FINANCIARE - - - ',
                    },
                    {
                        id: 'F01',
                        name: '1. Verificare incadrare buget',
                        filters: {
                            projectID: true,
                            position: true,
                            company: true,
                        },
                        columns: [
                            {
                                prop: 'funding_number',
                                label: 'Numar contract de finantare',
                                format: 'text',
                            }, {
                                prop: 'budget_line',
                                label: 'Linia bugetara',
                                format: 'text',
                            }, {
                                prop: 'position',
                                label: 'Pozitia in cadrul proiectului',
                                format: 'text',
                            }, {
                                prop: 'aplicant',
                                label: 'Alocat la',
                                format: 'text',
                            }, {
                                prop: 'activity',
                                label: 'Activitatea',
                                format: 'text',
                            }, {
                                prop: 'nume_cnp',
                                label: 'Nume si prenume/CNP',
                                format: 'text',
                            }, {
                                prop: 'ore_buget',
                                label: 'Numar total de ore bugetate',
                                format: 'integer',
                            }, {
                                prop: 'ore_lucrate',
                                label: 'Ore efectiv lucrate',
                                format: 'integer',
                            }, {
                                prop: 'ore_abatere',
                                label: 'Abatere fata de numarul total de ore bugetate',
                                format: 'integer',
                            }, {
                                prop: 'cost_total',
                                label: 'Cost total bugetat, EURO',
                                format: 'decimal',
                            }, {
                                prop: 'cost_total_raportat',
                                label: 'Cost total raportat, EURO',
                                format: 'decimal',
                            }, {
                                prop: 'cost_finantare_raportat',
                                label: 'Cheltuieli Finantare, EURO',
                                format: 'decimal',
                            }, {
                                prop: 'cost_cofinantare_raportat',
                                label: 'Cheltuieli CoFinantare, EURO',
                                format: 'decimal',
                            }, {
                                prop: 'total_abatere',
                                label: 'Abatere fata de costul total bugetat, EURO',
                                format: 'decimal',
                            }
                        ]
                    }, {
                        id: 'F02',
                        name: '2. Raport verificare cost orar unitar pe activitate',
                        filters: {
                            projectID: true,
                            name: true,
                            cnp: true,
                            company: true,
                            cim: true,
                        },
                        columns: [
                            {
                                prop: 'funding_number',
                                label: 'Numar contract de finantare',
                                format: 'text',
                            }, {
                                prop: 'organizatie',
                                label: 'Organizatie',
                                format: 'text',
                            }, {
                                prop: 'activitatea',
                                label: 'Activitatea',
                                format: 'text',
                            }, {
                                prop: 'linie_buget',
                                label: 'Linia buget',
                                format: 'text',
                            }, {
                                prop: 'pozitia',
                                label: 'Pozitia',
                                format: 'text',
                            }, {
                                prop: 'cnp',
                                label: 'CNP',
                                format: 'text',
                            }, {
                                prop: 'nume',
                                label: 'Nume',
                                format: 'text',
                            }, {
                                prop: 'prenume',
                                label: 'Prenume',
                                format: 'text',
                            }, {
                                prop: 'contract',
                                label: 'Nr. contract',
                                format: 'text',
                            }, {
                                prop: 'data_contract',
                                label: 'Data contract',
                                format: 'date',
                            }, {
                                prop: 'data_inceput',
                                label: 'Data inceput activitate',
                                format: 'date',
                            }, {
                                prop: 'data_finalizare',
                                label: 'Data finalizare activitate',
                                format: 'date',
                            }, {
                                prop: 'numar_raport',
                                label: 'Nr. raport financiar',
                                format: 'text',
                            }, {
                                prop: 'luna',
                                label: 'Luna raportarii',
                                format: 'text',
                            }, {
                                prop: 'ore_luna',
                                label: 'Ore lucratoare in luna',
                                format: 'integer',
                            }, {
                                prop: 'ore_lucrate',
                                label: 'Ore efectiv lucrate in luna',
                                format: 'integer',
                            }, {
                                prop: 'salariu_brut',
                                label: 'Salariul brut CIM',
                                format: 'decimal',
                            }, {
                                prop: 'cost_salarial',
                                label: 'Cost salarial CIM',
                                format: 'decimal',
                            }, {
                                prop: 'cost_orar',
                                label: 'Cost orar CIM',
                                format: 'decimal',
                            }, {
                                prop: 'cost_total_lei',
                                label: 'Cost total raportat Lei',
                                format: 'decimal',
                            }, {
                                prop: 'amount_financing',
                                label: 'Din care finantare Lei',
                                format: 'decimal',
                            }, {
                                prop: 'amount_cofinancing',
                                label: 'Din care cofinantare Lei',
                                format: 'decimal',
                            }, {
                                prop: 'cost_orar_raportat',
                                label: 'Cost orar raportat Lei',
                                format: 'decimal',
                            }, {
                                prop: 'curs',
                                label: 'Curs valutar',
                                format: 'decimal',
                            }, {
                                prop: 'cost_total_eur',
                                label: 'Cost total salarial Euro',
                                format: 'decimal',
                            }, {
                                prop: 'cost_finantare_eur',
                                label: 'Din care cheltuieli finantare Euro',
                                format: 'decimal',
                            }, {
                                prop: 'cost_cofinantare_eur',
                                label: 'Din care cheltuieli cofinantare Euro',
                                format: 'decimal',
                            }, {
                                prop: 'cost_orar_raportat_eur',
                                label: 'Cost orar raportat Euro',
                                format: 'decimal',
                            }, {
                                prop: 'cost_orar_bugetat',
                                label: 'Cost orar bugetat Euro',
                                format: 'decimal',
                            }, {
                                prop: 'abatere_cost_orar_raportat_fata_cim',
                                label: 'Abatere cost orar raportat fata de cim',
                                format: 'decimal',
                            }, {
                                prop: 'abatere_cost_orar_raportat_fata_cost_orar_bugetat',
                                label: 'Abatere cost orar raportat fata de cost orar bugetat',
                                format: 'decimal',
                            }
                        ]
                    }, {
                        id: 'F03',
                        name: '3. Raport verificare depasire norma CIM si calcul cost orar CIM vs cost orar raportat',
                        filters: {
                            projectID: true,
                            name: true,
                            cnp: true,
                            company: true,
                            cim: true,
                        },
                        columns: [
                            {
                                prop: 'cnp',
                                label: 'CNP',
                                format: 'text',
                            }, {
                                prop: 'nume',
                                label: 'Nume',
                                format: 'text',
                            }, {
                                prop: 'prenume',
                                label: 'Prenume',
                                format: 'text',
                            }, {
                                prop: 'contract',
                                label: 'Nr. contract',
                                format: 'text',
                            }, {
                                prop: 'data_contract',
                                label: 'Data contract',
                                format: 'date',
                            }, {
                                prop: 'data_inceput',
                                label: 'Data inceput activitate',
                                format: 'date',
                            }, {
                                prop: 'data_finalizare',
                                label: 'Data finalizare activitate',
                                format: 'date',
                            }, {
                                prop: 'organizatie',
                                label: 'Organizatie',
                                format: 'text',
                            }, {
                                prop: 'numar_contract_finantare',
                                label: 'Nr. contract finantare',
                                format: 'text',
                            }, {
                                prop: 'pozitia',
                                label: 'Pozitia',
                                format: 'text',
                            }, {
                                prop: 'luna',
                                label: 'Luna raportarii',
                                format: 'text',
                            }, {
                                prop: 'ore_lucratoare',
                                label: 'Ore lucratoare in luna',
                                format: 'integer',
                            }, {
                                prop: 'ore_lucrate',
                                label: 'Ore efectiv lucrate in luna',
                                format: 'integer',
                            }, {
                                prop: 'abatere_norma',
                                label: 'Abatere fata de norma',
                                format: 'integer',
                            }, {
                                prop: 'salariu_brut',
                                label: 'Salariu brut in luna',
                                format: 'decimal',
                            }, {
                                prop: 'cost_salarial',
                                label: 'Cost salarial CIM',
                                format: 'decimal',
                            }, {
                                prop: 'cost_total',
                                label: 'Cost total raportat',
                                format: 'decimal',
                            }, {
                                prop: 'cost_orar',
                                label: 'Cost orar raportat',
                                format: 'decimal',
                            }
                        ]
                    }, {
                        id: 'F04',
                        name: '4. Raport privind numarul de ore realizat pe contract',
                        filters: {
                            projectID: true,
                            name: true,
                            cnp: true,
                            company: true,
                            cim: true,
                        },
                        columns: [
                            {
                                prop: 'cnp',
                                label: 'CNP',
                                format: 'text',
                            }, {
                                prop: 'nume',
                                label: 'Nume',
                                format: 'text',
                            }, {
                                prop: 'prenume',
                                label: 'Prenume',
                                format: 'text',
                            }, {
                                prop: 'organizatie',
                                label: 'Organizatie',
                                format: 'text',
                            }, {
                                prop: 'numar_contract_finantare',
                                label: 'Nr. contract',
                                format: 'text',
                            }, {
                                prop: 'linie_buget',
                                label: 'Linie buget',
                                format: 'text',
                            }, {
                                prop: 'pozitia',
                                label: 'Pozitia',
                                format: 'text',
                            }, {
                                prop: 'contract',
                                label: 'Nr. contract',
                                format: 'text',
                            }, {
                                prop: 'data_contract',
                                label: 'Data contract',
                                format: 'date',
                            }, {
                                prop: 'data_inceput',
                                label: 'Data inceput activitate',
                                format: 'date',
                            }, {
                                prop: 'data_finalizare',
                                label: 'Data finalizare activitate',
                                format: 'date',
                            }, {
                                prop: 'total_cost_bugetat',
                                label: 'Total cost salarial bugetat',
                                format: 'decimal',
                            }, {
                                prop: 'cost_salarial',
                                label: 'Cost salarial raportat',
                                format: 'decimal',
                            }, {
                                prop: 'total_ore_bugetate',
                                label: 'Total ore bugetate',
                                format: 'integer',
                            }, {
                                prop: 'ore_raportare',
                                label: 'Total ore raportate',
                                format: 'integer',
                            }, {
                                prop: '2020-01',
                                label: 'Ianuarie-2020',
                                format: 'integer',
                            }, {
                                prop: '2020-02',
                                label: 'Februarie-2020',
                                format: 'integer',
                            }, {
                                prop: '2020-03',
                                label: 'Martie-2020',
                                format: 'integer',
                            }, {
                                prop: '2020-04',
                                label: 'Aprilie-2020',
                                format: 'integer',
                            }, {
                                prop: '2020-05',
                                label: 'Mai-2020',
                                format: 'integer',
                            }, {
                                prop: '2020-06',
                                label: 'Iunie-2020',
                                format: 'integer',
                            }, {
                                prop: '2020-07',
                                label: 'Iulie-2020',
                                format: 'integer',
                            }, {
                                prop: '2020-08',
                                label: 'August-2020',
                                format: 'integer',
                            }, {
                                prop: '2020-09',
                                label: 'Septembrie-2020',
                                format: 'integer',
                            }, {
                                prop: '2020-10',
                                label: 'Octombrie-2020',
                                format: 'integer',
                            }, {
                                prop: '2020-11',
                                label: 'Noiembrie-2020',
                                format: 'integer',
                            }, {
                                prop: '2020-12',
                                label: 'Decembrie-2020',
                                format: 'integer',
                            }

                            , {
                                prop: '2021-01',
                                label: 'Ianuarie-2021',
                                format: 'integer',
                            }, {
                                prop: '2021-02',
                                label: 'Februarie-2021',
                                format: 'integer',
                            }, {
                                prop: '2021-03',
                                label: 'Martie-2021',
                                format: 'integer',
                            }, {
                                prop: '2021-04',
                                label: 'Aprilie-2021',
                                format: 'integer',
                            }, {
                                prop: '2021-05',
                                label: 'Mai-2021',
                                format: 'integer',
                            }, {
                                prop: '2021-06',
                                label: 'Iunie-2021',
                                format: 'integer',
                            }, {
                                prop: '2021-07',
                                label: 'Iulie-2021',
                                format: 'integer',
                            }, {
                                prop: '2021-08',
                                label: 'August-2021',
                                format: 'integer',
                            }, {
                                prop: '2021-09',
                                label: 'Septembrie-2021',
                                format: 'integer',
                            }, {
                                prop: '2021-10',
                                label: 'Octombrie-2021',
                                format: 'integer',
                            }, {
                                prop: '2021-11',
                                label: 'Noiembrie-2021',
                                format: 'integer',
                            }, {
                                prop: '2021-12',
                                label: 'Decembrie-2021',
                                format: 'integer',
                            }

                            , {
                                prop: '2022-01',
                                label: 'Ianuarie-2022',
                                format: 'integer',
                            }, {
                                prop: '2022-02',
                                label: 'Februarie-2022',
                                format: 'integer',
                            }, {
                                prop: '2022-03',
                                label: 'Martie-2022',
                                format: 'integer',
                            }, {
                                prop: '2022-04',
                                label: 'Aprilie-2022',
                                format: 'integer',
                            }, {
                                prop: '2022-05',
                                label: 'Mai-2022',
                                format: 'integer',
                            }, {
                                prop: '2022-06',
                                label: 'Iunie-2022',
                                format: 'integer',
                            }, {
                                prop: '2022-07',
                                label: 'Iulie-2022',
                                format: 'integer',
                            }, {
                                prop: '2022-08',
                                label: 'August-2022',
                                format: 'integer',
                            }, {
                                prop: '2022-09',
                                label: 'Septembrie-2022',
                                format: 'integer',
                            }, {
                                prop: '2022-10',
                                label: 'Octombrie-2022',
                                format: 'integer',
                            }, {
                                prop: '2022-11',
                                label: 'Noiembrie-2022',
                                format: 'integer',
                            }, {
                                prop: '2022-12',
                                label: 'Decembrie-2022',
                                format: 'integer',
                            }

                            , {
                                prop: '2023-01',
                                label: 'Ianuarie-2023',
                                format: 'integer',
                            }, {
                                prop: '2023-02',
                                label: 'Februarie-2023',
                                format: 'integer',
                            }, {
                                prop: '2023-03',
                                label: 'Martie-2023',
                                format: 'integer',
                            }, {
                                prop: '2023-04',
                                label: 'Aprilie-2023',
                                format: 'integer',
                            }, {
                                prop: '2023-05',
                                label: 'Mai-2023',
                                format: 'integer',
                            }, {
                                prop: '2023-06',
                                label: 'Iunie-2023',
                                format: 'integer',
                            }, {
                                prop: '2023-07',
                                label: 'Iulie-2023',
                                format: 'integer',
                            }, {
                                prop: '2023-08',
                                label: 'August-2023',
                                format: 'integer',
                            }, {
                                prop: '2023-09',
                                label: 'Septembrie-2023',
                                format: 'integer',
                            }, {
                                prop: '2023-10',
                                label: 'Octombrie-2023',
                                format: 'integer',
                            }, {
                                prop: '2023-11',
                                label: 'Noiembrie-2023',
                                format: 'integer',
                            }, {
                                prop: '2023-12',
                                label: 'Decembrie-2023',
                                format: 'integer',
                            }

                            , {
                                prop: '2024-01',
                                label: 'Ianuarie-2024',
                                format: 'integer',
                            }, {
                                prop: '2024-02',
                                label: 'Februarie-2024',
                                format: 'integer',
                            }, {
                                prop: '2024-03',
                                label: 'Martie-2024',
                                format: 'integer',
                            }, {
                                prop: '2024-04',
                                label: 'Aprilie-2024',
                                format: 'integer',
                            }, {
                                prop: '2024-05',
                                label: 'Mai-2024',
                                format: 'integer',
                            }, {
                                prop: '2024-06',
                                label: 'Iunie-2024',
                                format: 'integer',
                            }, {
                                prop: '2024-07',
                                label: 'Iulie-2024',
                                format: 'integer',
                            }, {
                                prop: '2024-08',
                                label: 'August-2024',
                                format: 'integer',
                            }, {
                                prop: '2024-09',
                                label: 'Septembrie-2024',
                                format: 'integer',
                            }, {
                                prop: '2024-10',
                                label: 'Octombrie-2024',
                                format: 'integer',
                            }, {
                                prop: '2024-11',
                                label: 'Noiembrie-2024',
                                format: 'integer',
                            }, {
                                prop: '2024-12',
                                label: 'Decembrie-2024',
                                format: 'integer',
                            }
                        ]
                    }, {
                        id: 'F05',
                        name: '5. Raport verificare depasire cumul functii',
                        filters: {
                            projectID: true,
                            name: true,
                            cnp: true,
                            company: true,
                            cim: true,
                        },
                        columns: [
                            {
                                prop: 'cnp',
                                label: 'CNP',
                                format: 'text',
                            }, {
                                prop: 'nume',
                                label: 'Nume',
                                format: 'text',
                            }, {
                                prop: 'prenume',
                                label: 'Prenume',
                                format: 'text',
                            }, {
                                prop: 'linie_buget',
                                label: 'Tip contract salarial',
                                format: 'text',
                            }, {
                                prop: 'organizatie',
                                label: 'Organizatie',
                                format: 'text',
                            }, {
                                prop: 'numar_contract_finantare',
                                label: 'Proiectele in care lucreaza',
                                format: 'text',
                            }, {
                                prop: 'pozitia',
                                label: 'Pozitia',
                                format: 'text',
                            }, {
                                prop: 'ore_raportare',
                                label: 'Total ore raportate',
                                format: 'integer',
                            }, {
                                prop: '2020-01',
                                label: 'Ianuarie-2020',
                                format: 'integer',
                            }, {
                                prop: '2020-02',
                                label: 'Februarie-2020',
                                format: 'integer',
                            }, {
                                prop: '2020-03',
                                label: 'Martie-2020',
                                format: 'integer',
                            }, {
                                prop: '2020-04',
                                label: 'Aprilie-2020',
                                format: 'integer',
                            }, {
                                prop: '2020-05',
                                label: 'Mai-2020',
                                format: 'integer',
                            }, {
                                prop: '2020-06',
                                label: 'Iunie-2020',
                                format: 'integer',
                            }, {
                                prop: '2020-07',
                                label: 'Iulie-2020',
                                format: 'integer',
                            }, {
                                prop: '2020-08',
                                label: 'August-2020',
                                format: 'integer',
                            }, {
                                prop: '2020-09',
                                label: 'Septembrie-2020',
                                format: 'integer',
                            }, {
                                prop: '2020-10',
                                label: 'Octombrie-2020',
                                format: 'integer',
                            }, {
                                prop: '2020-11',
                                label: 'Noiembrie-2020',
                                format: 'integer',
                            }, {
                                prop: '2020-12',
                                label: 'Decembrie-2020',
                                format: 'integer',
                            }

                            , {
                                prop: '2021-01',
                                label: 'Ianuarie-2021',
                                format: 'integer',
                            }, {
                                prop: '2021-02',
                                label: 'Februarie-2021',
                                format: 'integer',
                            }, {
                                prop: '2021-03',
                                label: 'Martie-2021',
                                format: 'integer',
                            }, {
                                prop: '2021-04',
                                label: 'Aprilie-2021',
                                format: 'integer',
                            }, {
                                prop: '2021-05',
                                label: 'Mai-2021',
                                format: 'integer',
                            }, {
                                prop: '2021-06',
                                label: 'Iunie-2021',
                                format: 'integer',
                            }, {
                                prop: '2021-07',
                                label: 'Iulie-2021',
                                format: 'integer',
                            }, {
                                prop: '2021-08',
                                label: 'August-2021',
                                format: 'integer',
                            }, {
                                prop: '2021-09',
                                label: 'Septembrie-2021',
                                format: 'integer',
                            }, {
                                prop: '2021-10',
                                label: 'Octombrie-2021',
                                format: 'integer',
                            }, {
                                prop: '2021-11',
                                label: 'Noiembrie-2021',
                                format: 'integer',
                            }, {
                                prop: '2021-12',
                                label: 'Decembrie-2021',
                                format: 'integer',
                            }

                            , {
                                prop: '2022-01',
                                label: 'Ianuarie-2022',
                                format: 'integer',
                            }, {
                                prop: '2022-02',
                                label: 'Februarie-2022',
                                format: 'integer',
                            }, {
                                prop: '2022-03',
                                label: 'Martie-2022',
                                format: 'integer',
                            }, {
                                prop: '2022-04',
                                label: 'Aprilie-2022',
                                format: 'integer',
                            }, {
                                prop: '2022-05',
                                label: 'Mai-2022',
                                format: 'integer',
                            }, {
                                prop: '2022-06',
                                label: 'Iunie-2022',
                                format: 'integer',
                            }, {
                                prop: '2022-07',
                                label: 'Iulie-2022',
                                format: 'integer',
                            }, {
                                prop: '2022-08',
                                label: 'August-2022',
                                format: 'integer',
                            }, {
                                prop: '2022-09',
                                label: 'Septembrie-2022',
                                format: 'integer',
                            }, {
                                prop: '2022-10',
                                label: 'Octombrie-2022',
                                format: 'integer',
                            }, {
                                prop: '2022-11',
                                label: 'Noiembrie-2022',
                                format: 'integer',
                            }, {
                                prop: '2022-12',
                                label: 'Decembrie-2022',
                                format: 'integer',
                            }

                            , {
                                prop: '2023-01',
                                label: 'Ianuarie-2023',
                                format: 'integer',
                            }, {
                                prop: '2023-02',
                                label: 'Februarie-2023',
                                format: 'integer',
                            }, {
                                prop: '2023-03',
                                label: 'Martie-2023',
                                format: 'integer',
                            }, {
                                prop: '2023-04',
                                label: 'Aprilie-2023',
                                format: 'integer',
                            }, {
                                prop: '2023-05',
                                label: 'Mai-2023',
                                format: 'integer',
                            }, {
                                prop: '2023-06',
                                label: 'Iunie-2023',
                                format: 'integer',
                            }, {
                                prop: '2023-07',
                                label: 'Iulie-2023',
                                format: 'integer',
                            }, {
                                prop: '2023-08',
                                label: 'August-2023',
                                format: 'integer',
                            }, {
                                prop: '2023-09',
                                label: 'Septembrie-2023',
                                format: 'integer',
                            }, {
                                prop: '2023-10',
                                label: 'Octombrie-2023',
                                format: 'integer',
                            }, {
                                prop: '2023-11',
                                label: 'Noiembrie-2023',
                                format: 'integer',
                            }, {
                                prop: '2023-12',
                                label: 'Decembrie-2023',
                                format: 'integer',
                            }

                            , {
                                prop: '2024-01',
                                label: 'Ianuarie-2024',
                                format: 'integer',
                            }, {
                                prop: '2024-02',
                                label: 'Februarie-2024',
                                format: 'integer',
                            }, {
                                prop: '2024-03',
                                label: 'Martie-2024',
                                format: 'integer',
                            }, {
                                prop: '2024-04',
                                label: 'Aprilie-2024',
                                format: 'integer',
                            }, {
                                prop: '2024-05',
                                label: 'Mai-2024',
                                format: 'integer',
                            }, {
                                prop: '2024-06',
                                label: 'Iunie-2024',
                                format: 'integer',
                            }, {
                                prop: '2024-07',
                                label: 'Iulie-2024',
                                format: 'integer',
                            }, {
                                prop: '2024-08',
                                label: 'August-2024',
                                format: 'integer',
                            }, {
                                prop: '2024-09',
                                label: 'Septembrie-2024',
                                format: 'integer',
                            }, {
                                prop: '2024-10',
                                label: 'Octombrie-2024',
                                format: 'integer',
                            }, {
                                prop: '2024-11',
                                label: 'Noiembrie-2024',
                                format: 'integer',
                            }, {
                                prop: '2024-12',
                                label: 'Decembrie-2024',
                                format: 'integer',
                            }
                        ]
                    }, {
                        id: 'F06',
                        name: '6. Raport CDA',
                        filters: {
                            name: true,
                            cnp: true,
                            company: true,
                        },
                        columns: [
                            {
                                prop: 'numar_proiect',
                                label: 'Proiectele in care lucreaza',
                                format: 'text',
                            }, {
                                prop: 'cnp',
                                label: 'CNP',
                                format: 'text',
                            }, {
                                prop: 'nume',
                                label: 'Nume',
                                format: 'text',
                            }, {
                                prop: 'organizatie',
                                label: 'Organizatie',
                                format: 'text',
                            }, {
                                prop: 'numar_raportare',
                                label: 'Nr. raport financiar',
                                format: 'text',
                            }, {
                                prop: 'luna',
                                label: 'Luna pentru care se raporteaza',
                                format: 'text',
                            }, {
                                prop: 'cost_salarial',
                                label: 'Cost total raportat in luna',
                                format: 'decimal',
                            }, {
                                prop: 'numar_proiect',
                                label: 'Numar contract de finantare',
                                format: 'text',
                            }
                        ]
                    }, {
                        id: 'F07a',
                        name: '7a. Raport consolidare VRF',
                        filters: {
                            projectID: true,
                            // company: true,
                        },
                        columns: [
                            {
                                prop: 'numar_contract',
                                label: 'Numarul contractului de finantare',
                                format: 'text',
                            }, {
                                prop: 'numar_grace',
                                label: 'Numar GRACE',
                                format: 'text',
                            }, {
                                prop: 'cui_promotor',
                                label: 'CUI Promotor',
                                format: 'text',
                            }, {
                                prop: 'nume_organizatie',
                                label: 'Organizatie',
                                format: 'text',
                            }, {
                                prop: 'tip_organizatie',
                                label: 'Calitatea organizatiei',
                                format: 'text',
                            }, {
                                prop: 'tip_raport',
                                label: 'Tipul raportului',
                                format: 'text',
                            }, {
                                prop: 'perioada',
                                label: 'Perioada de raportare',
                                format: 'text',
                            }, {
                                prop: 'total_buget',
                                label: 'Buget aprobat la data raportarii',
                                format: 'decimal',
                            }, {
                                prop: 'total_dezv',
                                label: 'Buget aprobat pentru dezv org.',
                                format: 'decimal',
                            }, {
                                prop: 'total_finantare_eligibil',
                                label: 'Cheltuieli eligibile din finantare',
                                format: 'decimal',
                            }, {
                                prop: 'total_cofinantare_eligibil',
                                label: 'Cheltuieli eligibile din confinantare',
                                format: 'decimal',
                            }, {
                                prop: 'total_eligibil',
                                label: 'Total cheltuieli eligibile',
                                format: 'decimal',
                            }, {
                                prop: 'total_dezv_eligibil',
                                label: 'Total cheltuieli eligibile dezv. org.',
                                format: 'decimal',
                            }, {
                                prop: 'total_finantare_neeligibil',
                                label: 'Cheltuieli neeligibile din finantare',
                                format: 'decimal',
                            }, {
                                prop: 'total_cofinantare_neeligibil',
                                label: 'Cheltuieli neeligibile din cofinantare',
                                format: 'decimal',
                            }, {
                                prop: 'total_neeligibil',
                                label: 'Total cheltuieli neeligibile',
                                format: 'decimal',
                            }, {
                                prop: 'total_neeligibil_dezv',
                                label: 'Total cheltuieli neeligibile dezv. org.',
                                format: 'decimal',
                            }, {
                                prop: 'risk',
                                label: '% grad risk',
                                format: 'decimal',
                            }, {
                                prop: 'risk_sample',
                                label: 'Val. esantion selectat conf. grad risc',
                                format: 'decimal',
                            }, {
                                prop: 'selected_sample',
                                label: 'Val. esantion final selectat',
                                format: 'decimal',
                            }, {
                                prop: 'selected_percent',
                                label: '% esantion final selectat',
                                format: 'decimal',
                            }, {
                                prop: 'project_user',
                                label: 'Responsabil financiar',
                                format: 'text',
                            }, {
                                prop: 'fund_operator',
                                label: 'Operator',
                                format: 'text',
                            }, {
                                prop: 'appeal_name',
                                label: 'Apel',
                                format: 'text',
                            }, {
                                prop: 'round_name',
                                label: 'Runda',
                                format: 'text',
                            }, {
                                prop: 'grant_name',
                                label: 'Tip grant',
                                format: 'text',
                            }, {
                                prop: 'sent_date',
                                label: 'Data depunere',
                                format: 'date',
                            }, {
                                prop: 'approved_date',
                                label: 'Data validare',
                                format: 'date',
                            }, {
                                prop: 'pay_month',
                                label: 'Luna platii',
                                format: 'date',
                            }
                        ]
                    },  {
                        id: 'F07b',
                        name: '7b. Raport consolidare VRF-detaliat A/P',
                        filters: {
                            projectID: true,
                            company: true,
                        },
                        columns: [
                            {
                                prop: 'numar_contract',
                                label: 'Numarul contractului de finantare',
                                format: 'text',
                            }, {
                                prop: 'numar_grace',
                                label: 'Numar GRACE',
                                format: 'text',
                            }, {
                                prop: 'cui_promotor',
                                label: 'CUI Aplicant/Promotor',
                                format: 'text',
                            }, {
                                prop: 'nume_organizatie',
                                label: 'Organizatie',
                                format: 'text',
                            }, {
                                prop: 'tip_organizatie',
                                label: 'Calitatea organizatiei',
                                format: 'text',
                            }, {
                                prop: 'tip_raport',
                                label: 'Tipul raportului',
                                format: 'text',
                            }, {
                                prop: 'perioada',
                                label: 'Perioada de raportare',
                                format: 'text',
                            }, {
                                prop: 'total_buget',
                                label: 'Buget aprobat la data raportarii',
                                format: 'decimal',
                            }, {
                                prop: 'total_dezv',
                                label: 'Buget aprobat pentru dezv org.',
                                format: 'decimal',
                            }, {
                                prop: 'total_finantare_eligibil',
                                label: 'Cheltuieli eligibile din finantare',
                                format: 'decimal',
                            }, {
                                prop: 'total_cofinantare_eligibil',
                                label: 'Cheltuieli eligibile din confinantare',
                                format: 'decimal',
                            }, {
                                prop: 'total_eligibil',
                                label: 'Total cheltuieli eligibile',
                                format: 'decimal',
                            }, {
                                prop: 'total_dezv_eligibil',
                                label: 'Total cheltuieli eligibile dezv. org.',
                                format: 'decimal',
                            }, {
                                prop: 'total_finantare_neeligibil',
                                label: 'Cheltuieli neeligibile din finantare',
                                format: 'decimal',
                            }, {
                                prop: 'total_cofinantare_neeligibil',
                                label: 'Cheltuieli neeligibile din cofinantare',
                                format: 'decimal',
                            }, {
                                prop: 'total_neeligibil',
                                label: 'Total cheltuieli neeligibile',
                                format: 'decimal',
                            }, {
                                prop: 'total_neeligibil_dezv',
                                label: 'Total cheltuieli neeligibile dezv. org.',
                                format: 'decimal',
                            }, {
                                prop: 'project_user',
                                label: 'Responsabil financiar',
                                format: 'text',
                            }, {
                                prop: 'fund_operator',
                                label: 'Operator',
                                format: 'text',
                            }, {
                                prop: 'appeal_name',
                                label: 'Apel',
                                format: 'text',
                            }, {
                                prop: 'round_name',
                                label: 'Runda',
                                format: 'text',
                            }, {
                                prop: 'grant_name',
                                label: 'Tip grant',
                                format: 'text',
                            }, {
                                prop: 'sent_date',
                                label: 'Data depunere',
                                format: 'date',
                            }, {
                                prop: 'approved_date',
                                label: 'Data validare',
                                format: 'date',
                            }, {
                                prop: 'pay_month',
                                label: 'Luna platii',
                                format: 'date',
                            }
                        ]
                    }, {
                        id: 'F08a',
                        name: '8a. Raport verificare scadentar raportari',
                        filters: {
                            projectID: true,
                            responsive: true,
                            from_date: true,
                            to_date: true,
                        },
                        columns: [
                            {
                                prop: 'numar_contract',
                                label: 'Numarul contractului de finantare',
                                format: 'text',
                            }, {
                                prop: 'grace_number',
                                label: 'Numar Grace',
                                format: 'text',
                            }, {
                                prop: 'apel',
                                label: 'Apel',
                                format: 'text',
                            }, {
                                prop: 'runda',
                                label: 'Runda',
                                format: 'text',
                            }, {
                                prop: 'cif_promotor',
                                label: 'CIF Promotor',
                                format: 'text',
                            }, {
                                prop: 'cif_name',
                                label: 'Denumire promotor',
                                format: 'text',
                            }, {
                                prop: 'proiect_name',
                                label: 'Titlu proiect',
                                format: 'text',
                            }, {
                                prop: 'perioada',
                                label: 'Perioada',
                                format: 'text',
                            }, {
                                prop: 'document',
                                label: 'Document',
                                format: 'text',
                            }, {
                                prop: 'tip_raport',
                                label: 'Tip raport',
                                format: 'text',
                            }, {
                                prop: 'numar_raport',
                                label: 'Numar raport',
                                format: 'text',
                            }, {
                                prop: 'data_estimata',
                                label: 'Scadenta depunere',
                                format: 'text',
                            }, {
                                prop: 'data_transmitere',
                                label: 'Data transmitere',
                                format: 'text',
                            }, {
                                prop: 'diferenta',
                                label: 'Numarul zile intarziere',
                                format: 'text',
                            }, {
                                prop: 'scadenta_initiala',
                                label: 'Scadenta initala',
                                format: 'text',
                            }, {
                                prop: 'numar_solicitari',
                                label: 'Numar solicitari',
                                format: 'text',
                            }, {
                                prop: 'termen_raspuns',
                                label: 'Termen raspuns',
                                format: 'text',
                            }, {
                                prop: 'stare',
                                label: 'Stare',
                                format: 'text',
                            }, {
                                prop: 'data_aprobare',
                                label: 'Data aprobare',
                                format: 'text',
                            }, {
                                prop: 'durata_aprobare',
                                label: 'Durata aprobare',
                                format: 'text',
                            }, {
                                prop: 'responsabili',
                                label: 'Responsabili',
                                format: 'text',
                            }, {
                                prop: 'partener',
                                label: 'Partener responsabil',
                                format: 'text',
                            },
                        ]
                    }, {
                        id: 'F08b',
                        name: '8b. Raport verificare scadentar notificari',
                        filters: {
                            projectID: true,
                            responsive: true,
                            from_date: true,
                            to_date: true,
                        },
                        columns: [
                            {
                                prop: 'numar_contract',
                                label: 'Numarul contractului de finantare',
                                format: 'text',
                            }, {
                                prop: 'grace_number',
                                label: 'Nr Grace',
                                format: 'text',
                            }, {
                                prop: 'apel',
                                label: 'Apel',
                                format: 'text',
                            }, {
                                prop: 'runda',
                                label: 'Runda',
                                format: 'text',
                            }, {
                                prop: 'cif_promotor',
                                label: 'CIF Promotor',
                                format: 'text',
                            }, {
                                prop: 'cif_name',
                                label: 'Denumire promotor',
                                format: 'text',
                            }, {
                                prop: 'proiect_name',
                                label: 'Titlu proiect',
                                format: 'text',
                            },  {
                                prop: 'perioada',
                                label: 'Perioada',
                                format: 'text',
                            }, {
                                prop: 'document',
                                label: 'Document',
                                format: 'text',
                            }, {
                                prop: 'modificari',
                                label: 'Tip document',
                                format: 'text',
                            }, {
                                prop: 'numar_raport',
                                label: 'Numar raport',
                                format: 'text',
                            }, {
                                prop: 'data_transmitere',
                                label: 'Data transmitere',
                                format: 'text',
                            }, {
                                prop: 'data_scadenta',
                                label: 'Scandeta initiala',
                                format: 'text',
                            }, {
                                prop: 'data_raspuns',
                                label: 'Termen raspuns la ultima SC',
                                format: 'text',
                            }, {
                                prop: 'stare',
                                label: 'Stare',
                                format: 'text',
                            }, {
                                prop: 'data_aprobare',
                                label: 'Data aprobare',
                                format: 'text',
                            }, {
                                prop: 'numar_zile_verificare',
                                label: 'Numar zile verificare',
                                format: 'text',
                            }, {
                                prop: 'responsabili',
                                label: 'Responsabili',
                                format: 'text',
                            }, {
                                prop: 'partener',
                                label: 'Partener responsabil',
                                format: 'text',
                            },
                        ]
                    }, {
                        id: 'F09',
                        name: '9. Scadentar grafic raportari',
                        filters: {
                            projectID: true,
                            responsive: true,
                            fund_operator: true,
                        },
                        columns: [
                            {
                                prop: 'operator',
                                label: 'Operator',
                                format: 'text',
                            }, {
                                prop: 'numar_proiect',
                                label: 'Numar contract',
                                format: 'text',
                            }, {
                                prop: 'numar_raportare',
                                label: 'Numar raportare',
                                format: 'text',
                            }, {
                                prop: 'luna_depunere',
                                label: 'Luna depunere',
                                format: 'text',
                            }, {
                                prop: 'suma_estimata',
                                label: 'Suma estimata',
                                format: 'text',
                            }, {
                                prop: 'resp_financiar',
                                label: 'Responsabil financiar',
                                format: 'text',
                            }, {
                                prop: 'resp_tehnic',
                                label: 'Responsabil tehnic',
                                format: 'text',
                            }
                        ]
                    }, {
                        id: 'F10',
                        name: '10. Raport verificare plafon max finantare',
                        filters: {
                            cif: true,
                            company: true,
                        },
                        columns: [
                            {
                                prop: 'cif',
                                label: 'CIF',
                                format: 'text',
                            }, {
                                prop: 'name',
                                label: 'Denumire organizatie',
                                format: 'text',
                            }, {
                                prop: 'nr_proiecte',
                                label: 'Numar proiecte finantate din ACF',
                                format: 'text',
                            }, {
                                prop: 'id_proiecte',
                                label: 'Numar contract finantare',
                                format: 'text',
                            }, {
                                prop: 'tipuri_grant',
                                label: 'Tip grant',
                                format: 'text',
                            }, {
                                prop: 'total_finantare',
                                label: 'Suma totala din finantare',
                                format: 'decimal',
                            }, {
                                prop: 'parteneri',
                                label: 'Denumire parteneri',
                                format: 'text',
                            },
                        ]
                    }, {
                        id: 'F11',
                        name: '11. Raport verificare dubla finantare furnizori',
                        filters: {
                            projectID: true,
                            from_date: true,
                            to_date: true,
                        },
                        columns: [
                            {
                                prop: 'funding_number',
                                label: 'Numarul proiect',
                                format: 'text',
                            }, {
                                prop: 'tip',
                                label: 'Tip raport',
                                format: 'text',
                            }, {
                                prop: 'transmis_la',
                                label: 'Data trimiterii',
                                format: 'text',
                            }, {
                                prop: 'aprobat_la',
                                label: 'Data aprobarii',
                                format: 'text',
                            }, {
                                prop: 'stare',
                                label: 'Stare raport',
                                format: 'text',
                            }, {
                                prop: 'period',
                                label: 'Perioada raportarii',
                                format: 'text',
                            }, {
                                prop: 'activity',
                                label: 'Activitate',
                                format: 'text',
                            }, {
                                prop: 'category',
                                label: 'Capitol buget',
                                format: 'text',
                            }, {
                                prop: 'partner_id',
                                label: 'Cod aplicant/partener',
                                format: 'text',
                            }, {
                                prop: 'cif',
                                label: 'CIF aplicant/partener',
                                format: 'text',
                            }, {
                                prop: 'partner_name',
                                label: 'Nume aplicant/partener',
                                format: 'text',
                            }, {
                                prop: 'budget',
                                label: 'Linie de buget',
                                format: 'text',
                            }, {
                                prop: 'supplier',
                                label: 'CIF furnizor',
                                format: 'text',
                            }, {
                                prop: 'supplier_name',
                                label: 'Furnizor',
                                format: 'text',
                            }, {
                                prop: 'description',
                                label: 'Descrierea cheltuielii',
                                format: 'text',
                            }, {
                                prop: 'invoice_no',
                                label: 'Nr. doc. cost',
                                format: 'text',
                            }, {
                                prop: 'invoice_date',
                                label: 'Data doc. cost',
                                format: 'text',
                            }, {
                                prop: 'receipt_no',
                                label: 'Nr. doc. plata',
                                format: 'text',
                            }, {
                                prop: 'receipt_date',
                                label: 'Data doc. plata',
                                format: 'text',
                            }, {
                                prop: 'currency',
                                label: 'Moneda',
                                format: 'text',
                            }, {
                                prop: 'amount_cost',
                                label: 'Val. totala doc. cost',
                                format: 'text',
                            }, {
                                prop: 'amount_financing',
                                label: 'Chelt. finantare',
                                format: 'text',
                            }, {
                                prop: 'amount_cofinancing',
                                label: 'Chelt. cofinantare',
                                format: 'text',
                            }, {
                                prop: 'rate',
                                label: 'Curs EURO',
                                format: 'text',
                            }, {
                                prop: 'total_financing_spent',
                                label: 'Cheltuit din finantare',
                                format: 'text',
                            }, {
                                prop: 'total_cofinancing_spent',
                                label: 'Cheltuit din cofinantare',
                                format: 'text',
                            }, {
                                prop: 'total_spent',
                                label: 'Cost total',
                                format: 'text',
                            }, {
                                prop: 'dezv',
                                label: 'Comp. dezv.',
                                format: 'text',
                            }, {
                                prop: 'line_status',
                                label: 'Stare',
                                format: 'text',
                            }, {
                                prop: 'amount_financing2',
                                label: 'Chelt. din fin. accept.',
                                format: 'text',
                            }, {
                                prop: 'amount_cofinancing2',
                                label: 'Chelt. din cofin. accept.',
                                format: 'text',
                            }, {
                                prop: 'amount_total2',
                                label: 'Cost total acceptat',
                                format: 'text',
                            }, {
                                prop: 'dezv2',
                                label: 'Cost comp. dezv.',
                                format: 'text',
                            },
                        ]
                    },
                    {
                        id: 'T0',
                        name: '- - - RAPOARTE TEHNICE - - - ',
                    },
                    {
                        id: 'T03',
                        name: '3. Organizatii implicate',
                        filters: {
                            projectID: false
                        },
                        columns: [
                            {
                                prop: 'companie',
                                label: 'Companie',
                                format: 'text',
                            }, {
                                prop: 'cif',
                                label: 'CIF',
                                format: 'text',
                            }, {
                                prop: 'tip',
                                label: 'Tip',
                                format: 'text',
                            }, {
                                prop: 'tara',
                                label: 'Tara',
                                format: 'text',
                            }, {
                                prop: 'judet',
                                label: 'Judet',
                                format: 'text',
                            }, {
                                prop: 'nr_proiecte_promotor',
                                label: 'Nr proiecte promotor',
                                format: 'text',
                            }, {
                                prop: 'id_proiecte_promotor',
                                label: 'ID-uri proiecte promotor',
                                format: 'text',
                            }, {
                                prop: 'nr_pro_partener',
                                label: 'Nr proiecte partener',
                                format: 'text',
                            }, {
                                prop: 'id_pro_partener',
                                label: 'ID-uri proiecte partener',
                                format: 'text',
                            }, {
                                prop: 'total_aprobat',
                                label: 'Suma aprobata',
                                format: 'decimal',
                            }, {
                                prop: 'total_cheltuit',
                                label: 'Suma cheltuita',
                                format: 'decimal',
                            }, {
                                prop: 'risk_companie',
                                label: 'Grad de risc companie',
                                format: 'text',
                            }
                        ]
                    },
                    {
                        id: 'T04',
                        name: '4. Indicatori realizati cumulat',
                        filters: {
                            projectID: true
                        },
                        columns: [
                            {
                                prop: 'funding_number',
                                label: 'Numar',
                                format: 'text',
                            }, {
                                prop: 'graph',
                                label: 'Raportare',
                                format: 'text',
                            }, {
                                prop: 'period',
                                label: 'Perioada',
                                format: 'text',
                            }, {
                                prop: 'approved',
                                label: 'Aprobat la',
                                format: 'text',
                            }, {
                                prop: 'outcome',
                                label: 'Outcome',
                                format: 'text',
                            }, {
                                prop: 'output',
                                label: 'Output',
                                format: 'text',
                            }, {
                                prop: 'indicator',
                                label: 'Indicator',
                                format: 'text',
                            }, {
                                prop: 'target',
                                label: 'Target',
                                format: 'decimal',
                            }, {
                                prop: 'value',
                                label: 'Valoare',
                                format: 'decimal',
                            }, {
                                prop: 'value_up',
                                label: 'Numarator',
                                format: 'decimal',
                            }, {
                                prop: 'value_down',
                                label: 'Numitor',
                                format: 'decimal',
                            }, {
                                prop: 'age1',
                                label: 'Varsta: Copii si tineri (0-17 ani)',
                                format: 'decimal',
                            }, {
                                prop: 'age2',
                                label: 'Varsta: Tineri adulti (18-29 ani)',
                                format: 'decimal',
                            }, {
                                prop: 'age3',
                                label: 'Varsta: Adulti (30-64 ani)',
                                format: 'decimal',
                            }, {
                                prop: 'age4',
                                label: 'Varsta: Varstnici (65+ ani)',
                                format: 'decimal',
                            }, {
                                prop: 'age5',
                                label: 'Varsta: Nespecificat',
                                format: 'decimal',
                            }, {
                                prop: 'gender1',
                                label: 'Gen: Feminin',
                                format: 'decimal',
                            }, {
                                prop: 'gender2',
                                label: 'Gen: Masculin',
                                format: 'decimal',
                            }, {
                                prop: 'gender3',
                                label: 'Gen: Nespecificat/Altul',
                                format: 'decimal',
                            }, {
                                prop: 'ethnicity1',
                                label: 'Etnie: Roma',
                                format: 'decimal',
                            }, {
                                prop: 'ethnicity2',
                                label: 'Etnie: Nespecificat/Alta',
                                format: 'decimal',
                            }, {
                                prop: 'services1',
                                label: 'Servicii: Servicii conexe privind violență de gen',
                                format: 'decimal',
                            }, {
                                prop: 'services2',
                                label: 'Servicii: Servicii conexe privind egalitatea de gen',
                                format: 'decimal',
                            }, {
                                prop: 'techniques1',
                                label: 'Tehnici: Bună guvernanță',
                                format: 'decimal',
                            }, {
                                prop: 'techniques2',
                                label: 'Tehnici: Management eficient',
                                format: 'decimal',
                            }, {
                                prop: 'techniques3',
                                label: 'Tehnici: Diversificare surselor de finantare',
                                format: 'decimal',
                            }, {
                                prop: 'techniques4',
                                label: 'Tehnici: Comunicare strategică',
                                format: 'decimal',
                            }, {
                                prop: 'techniques5',
                                label: 'Tehnici: Monitorizare si evaluare',
                                format: 'decimal',
                            }, {
                                prop: 'techniques6',
                                label: 'Tehnici: Utilizarea datelor/cercetarilor',
                                format: 'decimal',
                            }, {
                                prop: 'techniques7',
                                label: 'Tehnici: Evaluarea capacitatii',
                                format: 'decimal',
                            }, {
                                prop: 'techniques8',
                                label: 'Tehnici: Nespecificat/Alta',
                                format: 'decimal'
                            }
                        ]
                    },
                    {
                        id: 'T05',
                        name: '5. Indicatori baseline cumulat',
                        filters: {
                            projectID: true
                        },
                        columns: [
                            {
                            //     prop: 'funding_number',
                            //     label: 'Numar',
                            //     format: 'text',
                            // }, {
                            //     prop: 'graph',
                            //     label: 'Raportare',
                            //     format: 'text',
                            // }, {
                            //     prop: 'period',
                            //     label: 'Perioada',
                            //     format: 'text',
                            // }, {
                            //     prop: 'approved',
                            //     label: 'Aprobat la',
                            //     format: 'text',
                            // }, {
                                prop: 'outcome',
                                label: 'Outcome',
                                format: 'text',
                            // }, {
                            //     prop: 'output',
                            //     label: 'Output',
                            //     format: 'text',
                            }, {
                                prop: 'indicator',
                                label: 'Indicator',
                                format: 'text',
                            }, {
                                prop: 'target',
                                label: 'Baseline',
                                format: 'decimal',
                            // }, {
                            //     prop: 'value',
                            //     label: 'Valoare',
                            //     format: 'decimal',
                            }, {
                                prop: 'value_up',
                                label: 'Numarator',
                                format: 'decimal',
                            }, {
                                prop: 'value_down',
                                label: 'Numitor',
                                format: 'decimal',
                            }, {
                                prop: 'age1',
                                label: 'Varsta: Copii si tineri (0-17 ani)',
                                format: 'decimal',
                            }, {
                                prop: 'age2',
                                label: 'Varsta: Tineri adulti (18-29 ani)',
                                format: 'decimal',
                            }, {
                                prop: 'age3',
                                label: 'Varsta: Adulti (30-64 ani)',
                                format: 'decimal',
                            }, {
                                prop: 'age4',
                                label: 'Varsta: Varstnici (65+ ani)',
                                format: 'decimal',
                            }, {
                                prop: 'age5',
                                label: 'Varsta: Nespecificat',
                                format: 'decimal',
                            }, {
                                prop: 'gender1',
                                label: 'Gen: Feminin',
                                format: 'decimal',
                            }, {
                                prop: 'gender2',
                                label: 'Gen: Masculin',
                                format: 'decimal',
                            }, {
                                prop: 'gender3',
                                label: 'Gen: Nespecificat/Altul',
                                format: 'decimal',
                            }, {
                                prop: 'ethnicity1',
                                label: 'Etnie: Roma',
                                format: 'decimal',
                            }, {
                                prop: 'ethnicity2',
                                label: 'Etnie: Nespecificat/Alta',
                                format: 'decimal',
                            }, {
                                prop: 'services1',
                                label: 'Servicii: Servicii conexe privind violență de gen',
                                format: 'decimal',
                            }, {
                                prop: 'services2',
                                label: 'Servicii: Servicii conexe privind egalitatea de gen',
                                format: 'decimal',
                            }, {
                                prop: 'techniques1',
                                label: 'Tehnici: Bună guvernanță',
                                format: 'decimal',
                            }, {
                                prop: 'techniques2',
                                label: 'Tehnici: Management eficient',
                                format: 'decimal',
                            }, {
                                prop: 'techniques3',
                                label: 'Tehnici: Diversificare surselor de finantare',
                                format: 'decimal',
                            }, {
                                prop: 'techniques4',
                                label: 'Tehnici: Comunicare strategică',
                                format: 'decimal',
                            }, {
                                prop: 'techniques5',
                                label: 'Tehnici: Monitorizare si evaluare',
                                format: 'decimal',
                            }, {
                                prop: 'techniques6',
                                label: 'Tehnici: Utilizarea datelor/cercetarilor',
                                format: 'decimal',
                            }, {
                                prop: 'techniques7',
                                label: 'Tehnici: Evaluarea capacitatii',
                                format: 'decimal',
                            }, {
                                prop: 'techniques8',
                                label: 'Tehnici: Nespecificat/Alta',
                                format: 'decimal'
                            }
                        ]
                    },
                    {
                        id: 'T06',
                        name: '6. Indicatori target cumulat',
                        filters: {
                            projectID: true
                        },
                        columns: [
                            {
                                //     prop: 'funding_number',
                                //     label: 'Numar',
                                //     format: 'text',
                                // }, {
                                //     prop: 'graph',
                                //     label: 'Raportare',
                                //     format: 'text',
                                // }, {
                                //     prop: 'period',
                                //     label: 'Perioada',
                                //     format: 'text',
                                // }, {
                                //     prop: 'approved',
                                //     label: 'Aprobat la',
                                //     format: 'text',
                                // }, {
                                prop: 'outcome',
                                label: 'Outcome',
                                format: 'text',
                            }, {
                                prop: 'output',
                                label: 'Output',
                                format: 'text',
                            }, {
                                prop: 'indicator',
                                label: 'Indicator',
                                format: 'text',
                            }, {
                                prop: 'target',
                                label: 'Baseline',
                                format: 'decimal',
                            }, {
                                prop: 'value',
                                label: 'Valoare',
                                format: 'decimal',
                            }, {
                                prop: 'value_up',
                                label: 'Numarator',
                                format: 'decimal',
                            }, {
                                prop: 'value_down',
                                label: 'Numitor',
                                format: 'decimal',
                            }
                        ]
                    },
                    {
                        id: 'Other',
                        name: '- - - ALTE RAPOARTE - - - ',
                    },
                    {
                        id: 'FMO',
                        name: 'Centralizator FMO',
                        filters: {
                            // projectID: true
                        },
                        columns: [
                            {
                                prop: 'funding_number',
                                label: 'Nr. contract',
                                format: 'text',
                            }, {
                                prop: 'grant',
                                label: 'Tip Grant',
                                format: 'text',
                            }, {
                                prop: 'financing',
                                label: 'Total finantare nerambursabila',
                                format: 'text',
                            }, {
                                prop: 'c1_total_financing',
                                label: '1.1 Chelt. finantare cu personalul',
                                format: 'decimal',
                            }, {
                                prop: 'c1_total_cofinancing',
                                label: '1.1 Chelt. cofinantare cu personalul',
                                format: 'decimal',
                            }, {
                                prop: 'c2_total_financing',
                                label: '1.2 Chelt. finantare cu transportul',
                                format: 'decimal',
                            }, {
                                prop: 'c2_total_cofinancing',
                                label: '1.2 Chelt. cofinantare cu transportul',
                                format: 'decimal',
                            }, {
                                prop: 'c3_total_financing',
                                label: '1.3 Chelt. finantare cu amortizare echip.',
                                format: 'decimal',
                            }, {
                                prop: 'c3_total_cofinancing',
                                label: '1.3 Chelt. cofinantare cu amortizare echip.',
                                format: 'decimal',
                            }, {
                                prop: 'c4_total_financing',
                                label: '1.4 Chelt. finantare cu costuri echip.',
                                format: 'decimal',
                            }, {
                                prop: 'c4_total_cofinancing',
                                label: '1.4 Chelt. cofinantare cu costuri echip.',
                                format: 'decimal',
                            }, {
                                prop: 'c5_total_financing',
                                label: '1.5 Chelt. finantare cu bunuri si consumabile',
                                format: 'decimal',
                            }, {
                                prop: 'c5_total_cofinancing',
                                label: '1.5 Chelt. cofinantare cu bunuri si consumabile',
                                format: 'decimal',
                            }, {
                                prop: 'c6_total_financing',
                                label: '1.6 Chelt. finantare cu alte contracte',
                                format: 'decimal',
                            }, {
                                prop: 'c6_total_cofinancing',
                                label: '1.6 Chelt. cofinantare cu alte contracte',
                                format: 'decimal',
                            }, {
                                prop: 'c7_total_financing',
                                label: '1.7 Chelt. finantare cu alte costuri',
                                format: 'decimal',
                            }, {
                                prop: 'c7_total_cofinancing',
                                label: '1.7 Chelt. cofinantare cu alte costuri',
                                format: 'decimal',
                            }, {
                                prop: 'ci_total_financing',
                                label: '2 Chelt. finantare indirecte',
                                format: 'decimal',
                            }, {
                                prop: 'ci_total_cofinancing',
                                label: '2 Chelt. cofinantare indirecte',
                                format: 'decimal',
                            }, {
                                prop: 'total_financing',
                                label: 'Total costuri finantare',
                                format: 'decimal',
                            }, {
                                prop: 'total_cofinancing',
                                label: 'Total costuri cofinantare',
                                format: 'decimal',
                            }
                        ]
                    },
                ],
                form: {
                    report: {
                        filters: null
                    },
                    selectedFilters: {},
                },
                loading: false,
                rules: {}
            }
        },
        computed:{
            ...mapGetters({
                reportData: 'report/reportData',
            }),
            filledValuesCount() {
                return [this.form.selectedFilters.company, this.form.selectedFilters.projectId,
                    this.form.selectedFilters.position, this.form.selectedFilters.name,
                    this.form.selectedFilters.responsive, this.form.selectedFilters.status,
                    this.form.selectedFilters.fund_operator, this.form.selectedFilters.cnp,
                    this.form.selectedFilters.cif, this.form.selectedFilters.from_date,
                    this.form.selectedFilters.to_date]
                    .map((value) => String(value || '').trim())
                    .filter(Boolean)
                    .length;
            },
            isValid() {
                return this.filledValuesCount >= 1;
            },
        },
        methods: {
            ...mapActions({
                runReport: 'report/runReport',
                exportReport: 'report/exportReport',
            }),
            ...mapMutations({
                setReportData: 'report/setReportData'
            }),
            submit() {
                this.$refs['reportForm'].validate((valid) => {
                    if (valid) { // && this.isValid
                        this.loading = true;
                        this.runReport({id: this.form.report.id, filters: this.form.selectedFilters}).then((result) => {
                            if(result.valid === false){
                                this.$notify({ type:'error', title: 'Eroare', message: result.message });
                            }
                            this.loading = false;
                        });
                        return true;
                    } else {
                        this.$notify({ type: 'warning', title: 'Centralizatoare', message: 'Introduceti cel putin un criteriu de filtrare!'});
                        return false;
                    }
                });
            },
            exportExcel() {
                this.$refs['reportForm'].validate((valid) => {
                    if (valid) { // && this.isValid
                        this.exportReport({report: this.form.report, filters: this.form.selectedFilters}).then((result) => {
                            if(result.valid === false){
                                this.$notify({ type:'error', title: 'Eroare', message: result.message });
                            }
                        });
                        return true;
                    } else {
                        this.$notify({ type: 'warning', title: 'Centralizatoare', message: 'Introduceti cel putin un criteriu de filtrare!'});
                        return false;
                    }
                });
            },
            clearData(){
                this.setReportData([]);
                this.filters = {};
            }
        },
    }
</script>
<style scoped>
</style>
