<template>
    <div>
        <form-wizard @on-complete="onComplete" :startIndex="0" ref="wizard"
             shape="tab" stepSize="xs" color="#3BB273" title="" subtitle="" layout="vertical"
             error-color="#E15554" v-loading="loading"
             @on-change="getTabChange"
        >
            <tab-content title="Informatii generale" :before-change="validateGeneralForm" v-if="reporting.status !== 5">
                <reporting-general></reporting-general>
            </tab-content>
           <tab-content :tabindex="activity" :index="index" :title="'Cheltuieli A' + activity.activity" :key="index"
                         :before-change="() => validateActivityForm(index)"
                         v-for="(activity, index) in project.activities"
                         v-if="reporting.status !== 5">
                <reporting-activity :newActivity="activityx" :activity-id="activity.id" :activityNumber="activity.activity" :activity-index="index"></reporting-activity>
            </tab-content>
            <tab-content title="Chelt. indirecte" :before-change="validateIndirectForm" v-if="reporting.status !== 5">
                <reporting-indirect></reporting-indirect>
            </tab-content>
            <tab-content title="Documente" :before-change="validateDocumentsForm">
                <reporting-documents></reporting-documents>
            </tab-content>
            <tab-content title="Centralizator raport" :before-change="validateReportForm">
                <reporting-report></reporting-report>
            </tab-content>
            <tab-content title="Centr. resurse umane">
                <reporting-hr></reporting-hr>
            </tab-content>
            <tab-content title="Detalii nota debit" :before-change="validateVerifyForm">
                <reporting-verify-form :verifyActivities="verifyActivities" :verifyIndirects="verifyIndirects"></reporting-verify-form>
            </tab-content>
            <tab-content title="Semnare nota de debit" :before-change="validateFinalDocumentForm">
                <reporting-financial-document></reporting-financial-document>
            </tab-content>
            <tab-content title="Finalizare" :before-change="validateFinalForm">
                <reporting-final></reporting-final>
            </tab-content>
            <template slot="footer" slot-scope="props">
                <div class=wizard-footer-left>
                    <a href="/reportings" class="ml-2 btn btn-default" v-if="props.activeTabIndex === 0" >Anulează, m-am răzgândit</a>
                    <wizard-button  v-if="props.activeTabIndex > 0"  @click.native="props.prevTab()" :style="props.fillButtonStyle">Înapoi</wizard-button>
                </div>
                <div class="wizard-footer-right">
                    <wizard-button v-if="!props.isLastStep" @click.native="props.nextTab()"
                                   class="btn btn-default" :style="props.fillButtonStyle">
                        Înainte
                    </wizard-button>
                    <wizard-button v-else @click.native="props.nextTab()"
                                   class="wizard-footer-right finish-button" :style="props.fillButtonStyle">
                        {{props.isLastStep ? 'Finalizează nota de debit' : 'Înainte'}}
                    </wizard-button>
                </div>
            </template>
        </form-wizard>
        <upload-drawer :user_data="user_id"></upload-drawer>
    </div>
</template>

<script>
import {FormWizard, TabContent, WizardButton} from 'vue-form-wizard';
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import ReportingGeneral from "./form-financial-general";
import ReportingActivity from "./form-financial-activity";
import ReportingIndirect from "./form-financial-indirect";
import ReportingHr from "./form-financial-hr";
import ReportingReport from "./form-financial-report";
import ReportingFinal from "./form-financial-final";
import ReportingDocuments from "./form-financial-documents";
import ReportingFinancialDocument from "./form-financial-document-final";
import {mapGetters, mapActions, mapMutations} from 'vuex'
import UploadDrawer from "../common/upload-drawer";
import {reportingDocuments} from '../../store/modules/reportings/getters';
import {getAuditIndirectData} from '../../store/modules/reportings/actions';
import {getIndirectCategories} from '../../store/modules/project/actions';
import ReportingVerifyForm from "../reportings-audit/form-financial-verify";

export default {
    name: "reporting-wizard",
    components: {
        UploadDrawer, ReportingVerifyForm,
        ReportingGeneral, ReportingActivity, ReportingIndirect, ReportingFinal, ReportingHr, ReportingReport,
        ReportingFinancialDocument, FormWizard, TabContent, WizardButton, ReportingDocuments
    },
    props:{
        project_data: {
            required: true,
            type: Object
        },
        reporting_data: {
            required: false,
            type: Object
        },
        section:{
            required: false,
            default: 0,
            type: Number
        },
        user_id: {
            required: true,
        }
    },
    data(){
        return {
            loading: false,
            activitiesVar: [],
            activityx: [],
            verifyActivities:[],
            verifyIndirects:[],
        }
    },
    computed:{
        ...mapGetters({
            project: 'project/project',
            reporting: 'reportings/reporting',
            validationErrorsExists: 'validationErrorsExists',
            // user: 'user',
            reportingDocuments: 'reportings/reportingDocuments',
        }),
        tab: function(n, o){
            // console.log(this.section);
            // if(this.section === 40){
            //     // console.log(this.project.activities.length);
            //     return 40 - 39 + this.project.activities.length;
            // }else if (this.section === 41){
            //     return 40 - 38 + this.project.activities.length;
            // }else{
                return this.section;
            // }
        }
    },
    created(){
        // this.getRates();
        this.getCostCategories();
        this.getIndirectCategories(0);
        this.setProject(this.project_data);
        if(this.reporting_data) this.setReporting(this.reporting_data);
    },
    mounted(){
        // this.reporting.user_id = this.user.id;
        // this.reporting.promoter_id = this.user.promoter[0].id;
        this.project.activities.forEach((value, index) => {
            this.activitiesVar.push(value);
        });
        //console.log(this.activitiesVar);
    },
    methods: {
        ...mapActions({
            getRates:'getRates',
            getCostCategories: 'project/getCostCategories',
            getDocuments: 'reportings/getReportingDocuments',
            saveReporting: 'reportings/saveAuditReporting',
            setValidateReport: 'reportings/setValidateReport',
            getAuditIndirects: 'reportings/getAuditIndirects',
            getBudgetLines: 'project/getBudgetLines',
            getIndirectCategories: 'project/getIndirectCategories',
            getAuditDocuments: 'reportings/getAuditDocuments',
            getAuditFinancialReport: 'reportings/getAuditFinancialReport',
            getIndirectData: 'reportings/getIndirectData',
            getActivitiesData: 'reportings/getActivitiesData',
        }),
        ...mapMutations({
            setProject: 'project/setProject',
            setReporting: 'reportings/setReporting',
            setValidateGeneral: 'reportings/setValidateGeneral',
            setValidateActivity: 'reportings/setValidateActivity',
            setValidateIndirect: 'reportings/setValidateIndirect',
            setValidateFinal: 'reportings/setValidateFinal',
        }),
        validateGeneralForm: function(){
            return new Promise((resolve, reject) => {
                this.loading = true;
                this.setValidateGeneral(true);
                setTimeout(() => {
                    if (!this.validationErrorsExists) {
                        if(this.reporting.status === 2){
                            resolve(true);
                        }else {
                            this.saveReporting(this.reporting).then((result) => {
                                if (result.valid === false) {
                                    this.$notify({type: 'error', title: 'Eroare', message: result.message});
                                    reject(false);
                                } else {
                                    // this.getDocuments({
                                    //     project_id: this.reporting.project_id,
                                    //     reporting_id: this.reporting.id,
                                    // });
                                    this.$notify({
                                        type: 'success',
                                        title: 'Salvare date',
                                        message: 'Datele raportării au fost salvate cu succes!'
                                    });
                                    this.loading = false;
                                    resolve(true);
                                }
                            });
                        }
                    } else {
                        this.$notify({type: 'warning', title: 'Validare date', message: 'Datele introduse nu sunt corecte. Verificați și încercați din nou.'});
                        this.loading = false;
                        reject(false);
                    }
                }, 1000)
            })
        },
        validateActivityForm: function(index){
            return new Promise((resolve, reject) => {
                this.setValidateActivity({index: index, value: true});
                setTimeout(() => {
                    if (!this.validationErrorsExists) {
                        this.loading = false;
                        resolve(true);
                    } else {
                        this.$notify({type: 'warning', title: 'Validare date', message: 'Datele introduse nu sunt corecte. Verificați și încercați din nou.'});
                        this.loading = false;
                        reject(false);
                    }
                }, 1000)
            })
        },
        validateIndirectForm: function(index){
            this.saveReporting(this.reporting).then((result) => {
                if(result.valid === false){
                    this.$notify({ type:'error', title: 'Eroare', message: result.message });
                    reject(false);
                }else{
                    this.$notify({type: 'success', title: 'Salvare date', message: 'Datele notei de debit au fost salvate cu succes!'});
                    //resolve(true);
                }
            });
            return new Promise((resolve, reject) => {
                this.setValidateIndirect({index : index, value: true});
                setTimeout(() => {
                    if (!this.validationErrorsExists) {
                        this.loading = false;
                        // this.saveReporting(this.reporting).then((result) => {
                        //     if(result.valid === false){
                        //         this.$notify({ type:'error', title: 'Eroare', message: result.message });
                        //         reject(false);
                        //     }else{
                        //         this.$notify({type: 'success', title: 'Salvare date', message: 'Datele raportării au fost salvate cu succes!'});
                        resolve(true);
                        //     }
                        // });
                    } else {
                        this.$notify({type: 'warning', title: 'Validare date', message: 'Datele introduse nu sunt corecte. Verificați și încercați din nou.'});
                        this.loading = false;
                        reject(false);
                    }
                }, 1000)
            })
        },
        validateDocumentsForm: function(){
            return new Promise((resolve, reject) => {
                this.loading = true;
                this.setValidateGeneral(true);
                setTimeout(() => {
                    if (!this.validationErrorsExists) {
                        // if(this.reporting.status === 2){
                        this.loading = false;
                        resolve(true);
                        // }else {
                        //     reject(false);
                        // }
                    } else {
                        this.$notify({type: 'warning', title: 'Validare date', message: 'Datele introduse nu sunt corecte. Verificați și încercați din nou.'});
                        this.loading = false;
                        reject(false);
                    }
                }, 1000)
            })
        },
        validateVerifyForm: function(){
            return new Promise((resolve, reject) => {
                this.loading = true;
                this.setValidateGeneral(true);
                setTimeout(() => {
                    if (!this.validationErrorsExists) {
                        // if(this.reporting.status === 2){
                        this.loading = false;
                        resolve(true);
                        // }else {
                        //     reject(false);
                        // }
                    } else {
                        this.$notify({type: 'warning', title: 'Validare date', message: 'Datele introduse nu sunt corecte. Verificați și încercați din nou.'});
                        this.loading = false;
                        reject(false);
                    }
                }, 1000)
            })
        },
        validateFinalDocumentForm: function(){
            return new Promise((resolve, reject) => {
                if(this.reporting.documents.filter((doc) => { return parseInt(doc.category_id) === 590 }).length === 0) {
                    this.$notify({type: 'warning', title: 'Lipsă fișier', message: 'Nu ai atașat nota de debit!'});
                    this.loading = false;
                    reject(true);
                } else {
                // if(this.reporting.names === null)
                // {
                //     this.$notify({type: 'warning', title: 'Completare campuri obligatorii!', message: 'Nu ai completat numele prestatorului de servicii de audit.'});
                //     this.loading = false;
                //     reject(true);
                //     return 1;
                // }

                    this.loading = true;
                    setTimeout(() => {
                        if (!this.validationErrorsExists) {
                            this.saveReporting(this.reporting).then((result) => {
                                // console.log(result);
                                if (result.valid === false) {
                                    this.$notify({type: 'error', title: 'Eroare', message: result.message});
                                    reject(false);
                                } else {
                                    console.log('success');
                                    this.$notify({
                                        type: 'success',
                                        title: 'Salvare date',
                                        message: 'Nota de debit a fost salvata cu succes!'
                                    });
                                    resolve(true);
                                }
                            });
                            resolve(true);
                            this.loading = false;
                        } else {
                            console.log('Validare raportare esuata');
                            this.loading = false;
                            reject(false);
                        }
                    }, 1000)
                }
            })
        },
        validateReportForm: function(){
            return new Promise((resolve, reject) => {
                this.setValidateReport(true)
                    .then(result => {
                        if (!this.validationErrorsExists) {
                            // this.reporting.status = 2;
                            // this.saveReporting(this.reporting).then((result) => {
                            //     if(result.valid === false){
                            //         this.$notify({ type:'error', title: 'Eroare', message: result.message });
                            //         reject(false);
                            //     }else{
                            //         this.$notify({type: 'success', title: 'Salvare date', message: 'Raportarea a fost salvata cu succes!'});
                            resolve(true);
                            //     }
                            // });
                        } else {
                            // console.log('Validare raport esuata');
                            this.loading = false;
                            reject(false);
                        }
                    })
            })
        },
        validateFinalForm: function(){
            return new Promise((resolve, reject) => {
                this.loading = true;
                setTimeout(() => {
                    if (!this.validationErrorsExists) {
                        this.reporting.final = true;
                        this.saveReporting(this.reporting).then((result) => {
                            // console.log(result);
                            if(result.valid === false){
                                this.$notify({ type:'error', title: 'Eroare', message: result.message });
                                this.loading = false;
                                setTimeout(function() {
                                    window.location.href = '/reportings'
                                },1000);
                                reject(false);
                            }else{
                                this.$notify({type: 'success', title: 'Salvare date', message: 'Nota de debit a fost transmisa cu succes!'});
                                this.loading = false;
                                setTimeout(function() {
                                    window.location.href = '/reportings'
                                },1000);
                                resolve(true);
                                setTimeout(function() {
                                    // window.location.href = '/reportings'
                                },1000);
                            }
                        });
                    } else {
                        console.log('Validare nota de debit esuata');
                        this.loading = false;
                        reject(false);
                    }
                }, 1000)
            })
        },

        getTabChange: function(a, b){
            if (parseInt(b) < this.project.activities.length + 1) {
                if (this.activitiesVar !== undefined && this.activitiesVar[b-1] !== undefined) {
                    axios.post('/reportings/loadActivityBudget',
                        {project_id: this.project.id, reporting_id: this.reporting.id, activity_id: this.activitiesVar[b-1].id}
                    ).then((result) => {
                        // activityx - store the value of the budget lines from the backend
                        this.activityx = result.data.activityLines;
                    });
                    // store information about line 1.1.1 in a variable for indirect costs to calculate
                    axios.post('/reportings/calculateMaxValueForIndirects',
                        {project_id: this.project.id, reporting_id: this.reporting.id}
                    ).then((result) => {
                        // activityx - store the value of the budget lines from the backend
                        this.reporting.indirectMaxValue = result.data.data;
                    });
                        //  get indirect data
                    // this.getAuditIndirects({
                    //     project_id: this.project.id,
                    //     reporting_id: this.reporting.id
                    // })
                }
            }
            if (parseInt(b) === this.project.activities.length + 1) {
                this.getIndirectCategories();
                this.getBudgetLines(106);

                this.getAuditIndirects({
                    project_id: this.project.id,
                    reporting_id: this.reporting.id
                })

            }
            if(parseInt(b) === this.project.activities.length + 2){
                this.getAuditDocuments({
                    reporting_id: this.reporting.id,
                });
            }

            if(parseInt(b) === this.project.activities.length + 5){  //
                this.loading = true;
                this.getActivitiesData({
                    project_id: this.project.id, reporting_id: this.reporting.id,
                }).then((results) => {
                    this.verifyActivities = results;
                    this.loading = false;
                });
                this.getIndirectData({
                    project_id: this.project.id, reporting_id: this.reporting.id,
                }).then((results) => {
                    this.verifyIndirects = results;
                    this.loading = false;
                });
            }
        },

        onComplete: function(){
            this.$notify({type: 'success', title: 'Raportare',  message: 'Nota de debit a fost salvată cu succes!'});
            setTimeout(function() {
                // window.location.href = '/reportings'
            },1000);
        },

        // thinningReporting(report){
        //     let thinReporting = report;
        //     thinReporting.ach = [];
        //     thinReporting.activities = [];
        //     thinReporting.documents = [];
        //     thinReporting.graph = [];
        //     thinReporting.indirects = [];
        //     thinReporting.risk = [];
        //     return thinReporting;
        // },
    }
}
</script>

<style>
.wizard-tab-content{
    padding-top:0 !important;
}
.wizard-nav-pills{
    width: 260px !important;
}
.wizard-nav-pills li{
    flex: none !important;
    flex-grow: 0 !important;
}
.tab_shape{
    display:none !important;
}
.wizard-btn{
    padding: 0.5rem 1.3rem !important;
    font-weight: 600 !important;
    font-size: 1.2rem !important;
    line-height: 1.4rem !important;
}
.stepTitle {
    border: 1px solid transparent !important;
    margin-bottom: 2px !important;
    padding: 0.75rem 1.25rem !important;
    background-color: #fff !important;
    width:100%;
}
.stepTitle.active{
    background-color: #3BB273 !important;
    font-weight: 700 !important;
    color:#fff !important;
}
.wizard-header{display:none;}
.wizard-card-footer{margin-left:220px;}
</style>
