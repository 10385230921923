<template>
    <el-form :model="reporting" ref="final" id="final" label-position="top" :status-icon="true" :inline-message="false">
        <div class="row">
            <div class="col-sm-12">
                <h3>Finalizare</h3>
            </div>
            <div class="col-sm-12">
                <div class="form-group">
                    <h5 class="text-muted alert alert-info mb-4" style="margin-bottom:0;">
                        Nota de debit este finalizată. După apăsarea butonului <i>Transmite nota de debit</i> nicio modificare nu va mai fi posibilă.<br/>
                    </h5>
                    <h5 class="text-muted alert alert-info mb-4" style="margin-bottom:0;">
                        Apasă butonul de mai jos pentru a trimite o notificare Promotorului, pentru verificarea Notei de debit.<br />
                    </h5>
                    <el-button type="secondary" class="float-right" icon="el-icon-edit" @click="notifyPromoter()" :loading="loadingButton">
                      <span>Trimite notificare Promotor</span>
                    </el-button>
                </div>
            </div>
        </div>
    </el-form>
</template>

<script>
    import { mapGetters, mapActions, mapMutations } from 'vuex'
    import mixin from './../../mixins/common';
    import moment from 'moment';
    import numeral from 'numeral';
    import VueScrollTo from "vue-scrollto";
    export default {
        name: "reporting-final-form",
        mixins:[mixin],
        data(){
            return {
                loadingButton: false
            }
        },
        computed:{
            ...mapGetters({
                reporting: 'reportings/reporting',
                validateFinal: 'reportings/validateFinal',
                validationErrorsExists: 'validationErrorsExists',
            }),
        },
        watch:{
            validateFinal: function(){
                if(this.validateFinal) {
                    this.setValidateFinal(false);
                    this.submitForm();
                }
            },
        },
        methods:{
            ...mapActions({
                sendAuditEmail: 'reportings/sendAuditEmail',
                saveReporting: 'reportings/saveAuditReporting',
            }),
            ...mapMutations({
                setValidateFinal: 'reportings/setValidateFinal',
                setValidationErrorsExists: 'setValidationErrorsExists',
            }),
            notifyPromoter(){
                this.$refs['final'].validate((valid) => {
                    if (valid) {
                        this.reporting.final = true;
                        this.saveReporting(this.reporting).then((result) => {
                            if(result.valid === true){
                                this.$notify({type: 'success', title: 'Salvare date', message: 'Nota de debit a fost transmisa cu succes!'});
                            }
                        });
                        this.loadingButton = true;
                        this.sendAuditEmail({
                            reporting_id: this.reporting.id,
                            project_id: this.reporting.project_id,
                        }).then(_ => {
                            this.loadingButton = false;
                            this.$notify({
                                type: 'success',
                                title: 'Finalizare notă debit!',
                                message: 'Mesajul a fost trimis prin email!'
                            });
                        })
                    }
                })
            },
            submitForm() {
                this.$refs['final'].validate((valid) => {
                    if (valid) {
                        this.setValidateFinal(false);
                        this.setValidationErrorsExists(false);
                        VueScrollTo.scrollTo('#final');
                        return true;
                    } else {
                        VueScrollTo.scrollTo('#final');
                        this.setValidationErrorsExists(true);
                        return false;
                    }
                })
            },
        }
    }
</script>

<style scoped>

</style>
