

class Errors{
    constructor(){
        this.errors = {};
    }
    /**
     * Retrieve the error message for a field.
     *
     * @param {string} field
     */
    get(field){
        if(this.errors[field]){
            return this.errors[field][0];
        }
    }
    /**
     * Determine if an errors exists for the given field.
     *
     * @param {string} field
     */
    has(field) {
        return this.errors.hasOwnProperty(field);
    }
    /**
     * Determine if we have any errors.
     */
    any() {
        return Object.keys(this.errors).length > 0;
    }
    /**
     * Clear one or all error fields.
     *
     * @param {string|null} field
     */
    clear(field = null) {
        if (field) {
            if (this.has(field)) {
                delete this.errors[field];
            }
            return;
        }
        this.errors = {};
    }
}

export default{
    cities: [],
    counties: [],
    countries: [],
    companies: [],
    validationErrors: new Errors(),
    validationErrorsExists: false,
    showUploadDrawer: {},
    showUploadDrawer2: {},
    rates: [],
    dayRate: [],
    autoClose: false,
};
