<template>
    <div>
        <div class="row">
            <div class="col-sm-6">
                <label>Numar contract finantare</label>
                <p>{{ project.funding_number }}</p>
            </div>
            <div class="col-sm-6">
                <label>Titlul proiectului</label>
                <p>{{ project.name }}</p>
            </div>
            <div class="col-sm-6">
                <label>Numele promotorului</label>
                <p>{{project.promoter.name}}</p>
            </div>
            <div class="col-sm-6">
                <label>Numele persoanei care a revizuit rapoartele / Denumirea prestatorului de servicii de audit</label>
                <p>{{ reporting.audit_agent }}</p>
            </div>
            <div class="col-sm-6">
                <label>Perioada verificata</label>
                <p>{{ formatUserDate(project.implementation_start_date) }} - {{ formatUserDate(project.implementation_end_date) }}</p>
            </div>
            <div class="col-sm-3">
                <label>Data transmiterii</label>
                <p>{{formatUserDate(reporting.updated_at)}}</p>
            </div>
            <div class="col-sm-3">
                <label>Stare</label>
                <p>
                    <span class="badge" style="background-color:#FDD9B5;" v-if="reporting.status === 1">Ciornă</span>
                    <span class="badge" style="background-color:#B99ABF" v-if="reporting.status === 2">Trimis</span>
                    <span class="badge badge-success" v-if="reporting.status === 3">Aprobat</span>
                    <span class="badge badge-danger" v-if="(reporting.status === 4 || reporting.status === 7) && validAnswerLimit(reporting.answer_limit)">Necesită modificări</span>
                    <span class="badge badge-danger" v-if="(reporting.status === 4 || reporting.status === 7) && !validAnswerLimit(reporting.answer_limit)">Transmis fara raspuns la SC</span>
                    <span class="badge" style="background-color:#FDD9B5;" v-if="reporting.status === 5">Ciornă - fără modificări</span>
                    <span class="badge badge-danger" v-if="reporting.status === 6">Audit</span>
                    <span class="badge badge-success" v-if="reporting.status === 8">Trimis</span>
                    <span class="badge badge-success" v-if="reporting.status === 9">Finalizat</span>
                </p>
            </div>
            <div class="col-sm-3">
                <label>Nota de debit</label>
                <p>
                    <el-button type="secondary" class="btn btn-sm btn-secondary" round size="small" icon="el-icon-view" @click.prevent="viewDebitNote()">
                      Vezi nota de debit
                    </el-button>
                </p>
            </div>
            <div class="col-sm-3">
                <label>Documente</label>
                <p>
                    <el-button type="secondary" size="small" icon="el-icon-view" @click="viewDocuments()">
                      <span>Vizualizare listă</span>
                    </el-button>
                </p>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <h3 class="mt-5">
                    Centralizator raport
                </h3>
            </div>
            <div class="col-6">
                <div class="alert alert-success">Selectează o activitate pentru a afișa detaliile!</div>
            </div>
            <div class="col-6 text-right">
                <el-button type="secondary" class="btn btn-sm btn-secondary ml-5" round @click.prevent="openUrl('/reportings/audit/exportFinancialReport/' + reporting.id + '/' + project.id, true)">
                    Export in Excel
                </el-button>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-sm-12">
                <el-tabs v-model="reportTabs" @tab-click="selectTab" v-loading="loading">
                    <el-tab-pane :label="'A'+activity.activity" :name="'activity' + activity.activity" v-for="activity in project.activities" :key="activity.id">
                        <template slot="label">
                            <el-tooltip position="top">
                                <div slot="content">{{ activity.name }}</div>
                                <span>A{{ activity.activity }}</span>
                            </el-tooltip>
                        </template>
                        <el-table :data="financialReportReported[activity.id]" :summary-method="getSummaries" show-summary size="mini">
                            <el-table-column show-overflow-tooltip label="Linie buget" width="200">
                                <template slot-scope="scope">
                                    <span v-if="scope.row.name">{{ scope.row.name }}</span>
                                </template>
                            </el-table-column>
                            <!--                            <el-table-column label="Linie buget" prop="budget"></el-table-column>-->
                            <el-table-column label="Buget aprobat" align="center">
                                <el-table-column label="Total" align="right" header-align="center" prop="total" width="115">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.total) }}</template>
                                </el-table-column>
                                <el-table-column label="Comp. dezv." align="right" header-align="center" prop="dezv" width="100">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.dezv) }}</template>
                                </el-table-column>
                            </el-table-column>
                            <el-table-column label="Cheltuieli acceptate raportari precedente" align="center">
                                <el-table-column label="Din finantare" align="right" header-align="center" prop="totalReportingOldFinancing">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.totalReportingOldFinancing) }}</template>
                                </el-table-column>
                                <el-table-column label="Din cofinantare" align="right" header-align="center" prop="totalReportingOldCoFinancing">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.totalReportingOldCoFinancing) }}</template>
                                </el-table-column>
                                <el-table-column label="Total" align="right" header-align="center" prop="totalReportingOld">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.totalReportingOld) }}</template>
                                </el-table-column>
                                <el-table-column label="Dezv. org." align="right" header-align="center" prop="totalReportingOldDezv">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.totalReportingOldDezv) }}</template>
                                </el-table-column>
                            </el-table-column>
                            <el-table-column label="Cheltuieli neeligibile ale perioadei raportate" align="center">
                                <el-table-column label="Din finantare" align="right" header-align="center" prop="totalReportingNowFinancing">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.totalReportingNowFinancing) }}</template>
                                </el-table-column>
                                <el-table-column label="Din cofinantare" align="right" header-align="center" prop="totalReportingNowCoFinancing">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.totalReportingNowCoFinancing) }}</template>
                                </el-table-column>
                                <el-table-column label="Total" align="right" header-align="center" prop="totalReportingNow">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.totalReportingNow) }}</template>
                                </el-table-column>
                                <el-table-column label="Dezv. org." align="right" header-align="center" prop="totalReportingNowDezv">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.totalReportingNowDezv) }}</template>
                                </el-table-column>
                            </el-table-column>
                            <el-table-column label="Sume necheltuite" align="center">
                                <el-table-column label="Din buget" align="right" header-align="center" prop="diff_total" width="115">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.diff_total) }}</template>
                                </el-table-column>
                                <el-table-column label="Din dezv. org." align="right" header-align="center" prop="diff_dezv" width="100">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.diff_dezv) }}</template>
                                </el-table-column>
                            </el-table-column>
                        </el-table>
                    </el-tab-pane>
                    <el-tab-pane label="Indirecte" name="indirects">
                        <template slot="label">
                            <el-tooltip position="top">
                                <div slot="content">Cheltuieli indirecte</div>
                                <span>Indirecte</span>
                            </el-tooltip>
                        </template>
                        <el-table :data="financialIndirectReport" :summary-method="getSummaries" show-summary size="mini">
                            <el-table-column show-overflow-tooltip label="Aplicant/Partener" width="200">
                                <template slot-scope="scope">
                                    <span v-if="scope.row.name">{{ scope.row.name }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="Buget aprobat" align="center">
                                <el-table-column label="Total" align="right" header-align="center" prop="total" width="115">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.total) }}</template>
                                </el-table-column>
                                <el-table-column label="Comp. dezv." align="right" header-align="center" prop="dezv" width="100">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.dezv) }}</template>
                                </el-table-column>
                            </el-table-column>
                            <el-table-column label="Cheltuieli acceptate raportari precedente" align="center">
                                <el-table-column label="Din finantare" align="right" header-align="center" prop="totalReportingOldFinancing">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.totalReportingOldFinancing) }}</template>
                                </el-table-column>
                                <el-table-column label="Din cofinantare" align="right" header-align="center" prop="totalReportingOldCoFinancing">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.totalReportingOldCoFinancing) }}</template>
                                </el-table-column>
                                <el-table-column label="Total" align="right" header-align="center" prop="totalReportingOld">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.totalReportingOld) }}</template>
                                </el-table-column>
                                <el-table-column label="Dezv. org." align="right" header-align="center" prop="totalReportingOldDezv">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.totalReportingOldDezv) }}</template>
                                </el-table-column>
                            </el-table-column>
                            <el-table-column label="Cheltuieli neeligibile ale perioadei raportate" align="center">
                                <el-table-column label="Din finantare" align="right" header-align="center" prop="totalReportingNowFinancing">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.totalReportingNowFinancing) }}</template>
                                </el-table-column>
                                <el-table-column label="Din cofinantare" align="right" header-align="center" prop="totalReportingNowCoFinancing">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.totalReportingNowCoFinancing) }}</template>
                                </el-table-column>
                                <el-table-column label="Total" align="right" header-align="center" prop="totalReportingNow">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.totalReportingNow) }}</template>
                                </el-table-column>
                                <el-table-column label="Dezv. org." align="right" header-align="center" prop="totalReportingNowDezv">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.totalReportingNowDezv) }}</template>
                                </el-table-column>
                            </el-table-column>
                            <el-table-column label="Sume necheltuite" align="center">
                                <el-table-column label="Din buget" align="right" header-align="center" prop="diff_total" width="115">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.diff_total) }}</template>
                                </el-table-column>
                                <el-table-column label="Din dezv. org." align="right" header-align="center" prop="diff_dezv" width="100">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.diff_dezv) }}</template>
                                </el-table-column>
                            </el-table-column>
                        </el-table>
                    </el-tab-pane>
                    <el-tab-pane label="Buget sintetic" name="synthetic">
                        <el-table :data="syntheticReport" :summary-method="getSummaries" show-summary size="mini">
                            <el-table-column show-overflow-tooltip label="Linie de buget" width="200">
                                <template slot-scope="scope">
                                    <span v-if="scope.row.name">{{ scope.row.name }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="Buget aprobat" align="center">
                                <el-table-column label="Total" align="right" header-align="center" prop="total" width="115">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.total) }}</template>
                                </el-table-column>
                                <el-table-column label="Comp. dezv." align="right" header-align="center" prop="dezv" width="100">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.dezv) }}</template>
                                </el-table-column>
                            </el-table-column>
                            <el-table-column label="Cheltuieli acceptate raportari precedente" align="center">
                                <el-table-column label="Din finantare" align="right" header-align="center" prop="totalReportingOldFinancing">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.totalReportingOldFinancing) }}</template>
                                </el-table-column>
                                <el-table-column label="Din cofinantare" align="right" header-align="center" prop="totalReportingOldCoFinancing">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.totalReportingOldCoFinancing) }}</template>
                                </el-table-column>
                                <el-table-column label="Total" align="right" header-align="center" prop="totalReportingOld">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.totalReportingOld) }}</template>
                                </el-table-column>
                                <el-table-column label="Dezv. org." align="right" header-align="center" prop="totalReportingOldDezv">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.totalReportingOldDezv) }}</template>
                                </el-table-column>
                            </el-table-column>
                            <el-table-column label="Cheltuieli neeligibile ale perioadei raportate" align="center">
                                <el-table-column label="Din finantare" align="right" header-align="center" prop="totalReportingNowFinancing">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.totalReportingNowFinancing) }}</template>
                                </el-table-column>
                                <el-table-column label="Din cofinantare" align="right" header-align="center" prop="totalReportingNowCoFinancing">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.totalReportingNowCoFinancing) }}</template>
                                </el-table-column>
                                <el-table-column label="Total" align="right" header-align="center" prop="totalReportingNow">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.totalReportingNow) }}</template>
                                </el-table-column>
                                <el-table-column label="Dezv. org." align="right" header-align="center" prop="totalReportingNowDezv">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.totalReportingNowDezv) }}</template>
                                </el-table-column>
                            </el-table-column>
                            <el-table-column label="Sume necheltuite" align="center">
                                <el-table-column label="Din buget" align="right" header-align="center" prop="diff_total" width="115">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.diff_total) }}</template>
                                </el-table-column>
                                <el-table-column label="Din dezv. org." align="right" header-align="center" prop="diff_dezv" width="100">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.diff_dezv) }}</template>
                                </el-table-column>
                            </el-table-column>
                        </el-table>
                    </el-tab-pane>
                    <el-tab-pane label="Total pe parteneri" name="partners">
                        <el-table :data="financialPartners" :summary-method="getSummaries" show-summary size="mini">
                            <el-table-column show-overflow-tooltip label="Promotor / Partener" width="200">
                                <template slot-scope="scope">
                                    <span v-if="scope.row.name">{{ scope.row.name }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="Buget aprobat" align="center">
                                <el-table-column label="Total" align="right" header-align="center" prop="total" width="115">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.total) }}</template>
                                </el-table-column>
                                <el-table-column label="Comp. dezv." align="right" header-align="center" prop="dezv" width="100">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.dezv) }}</template>
                                </el-table-column>
                            </el-table-column>
                            <el-table-column label="Cheltuieli acceptate raportari precedente" align="center">
                                <el-table-column label="Din finantare" align="right" header-align="center" prop="totalReportingOldFinancing">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.totalReportingOldFinancing) }}</template>
                                </el-table-column>
                                <el-table-column label="Din cofinantare" align="right" header-align="center" prop="totalReportingOldCoFinancing">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.totalReportingOldCoFinancing) }}</template>
                                </el-table-column>
                                <el-table-column label="Total" align="right" header-align="center" prop="totalReportingOld">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.totalReportingOld) }}</template>
                                </el-table-column>
                                <el-table-column label="Dezv. org." align="right" header-align="center" prop="totalReportingOldDezv">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.totalReportingOldDezv) }}</template>
                                </el-table-column>
                            </el-table-column>
                            <el-table-column label="Cheltuieli neeligibile ale perioadei raportate" align="center">
                                <el-table-column label="Din finantare" align="right" header-align="center" prop="totalReportingNowFinancing">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.totalReportingNowFinancing) }}</template>
                                </el-table-column>
                                <el-table-column label="Din cofinantare" align="right" header-align="center" prop="totalReportingNowCoFinancing">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.totalReportingNowCoFinancing) }}</template>
                                </el-table-column>
                                <el-table-column label="Total" align="right" header-align="center" prop="totalReportingNow">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.totalReportingNow) }}</template>
                                </el-table-column>
                                <el-table-column label="Dezv. org." align="right" header-align="center" prop="totalReportingNowDezv">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.totalReportingNowDezv) }}</template>
                                </el-table-column>
                            </el-table-column>
                            <el-table-column label="Sume necheltuite" align="center">
                                <el-table-column label="Din buget" align="right" header-align="center" prop="diff_total" width="115">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.diff_total) }}</template>
                                </el-table-column>
                                <el-table-column label="Din dezv. org." align="right" header-align="center" prop="diff_dezv" width="100">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.diff_dezv) }}</template>
                                </el-table-column>
                            </el-table-column>
                        </el-table>
                    </el-tab-pane>
                    <el-tab-pane label="Surse de finantare" name="totals">
                        <table class="table table-responsive el-table el-table--fit el-table--border el-table--group el-table--enable-row-hover el-table--enable-row-transition el-table--mini">
                            <thead>
                            <tr>
                                <th style="width:40%;white-space:normal;">Sursele de finantare </th>
                                <th style="width:10%;white-space:normal;">Sume aprobate conform contractului de finantare</th>
                                <th style="width:10%;white-space:normal;">% conform contractului de finantare</th>
                                <th style="width:10%;white-space:normal;">Sume raportate ca fiind eligibile</th>
                                <th style="width:10%;white-space:normal;">% cheltuielilor raportate si acceptate ca fiind eligibile pe surse de finantare</th>
                                <th style="width:10%;white-space:normal;">Procentul cheltuirii bugetului</th>
                                <th style="width:10%;white-space:normal;">Procentul bugetului ramas de cheltuit</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>
                                    1. Finantare nerambursabila solicitata
                                </td>
                                <td class="text-right">
                                    {{ formatPrice(this.source.totalIrredeemable) }}
                                </td>
                                <td class="text-right">
                                    {{ formatPercent(this.source.totalIrredeemable/this.source.totalEligible) }}
                                </td>
                                <td class="text-right">
                                    {{ formatPrice(this.source.totalEligibleReported) }}
                                </td>
                                <td class="text-right">
                                    {{ formatPercent(this.source.totalEligibleReported/this.source.totalProjectReported) }}
                                </td>
                                <td class="text-right">
                                    {{ formatPercent(this.source.totalEligibleReported/this.source.totalIrredeemable) }}
                                </td>
                                <td class="text-right">
                                    {{ formatNumber(100 - (this.source.totalEligibleReported / this.source.totalIrredeemable * 100)) }}%
                                </td>
                            </tr>
                            <tr>
                                <td>2. Cofinantare Aplicant si Parteneri</td>
                                <td class="text-right">
                                    {{ formatPrice(this.source.totalCoFunding) }}
                                </td>
                                <td class="text-right">
                                    {{ formatPercent(this.source.totalCoFunding/this.source.totalEligible) }}
                                </td>
                                <td class="text-right">
                                    {{ formatPrice(this.source.totalCoFundingReported) }}
                                </td>
                                <td class="text-right">
                                    {{ formatPercent(this.source.totalCoFundingReported/this.source.totalProjectReported) }}
                                </td>
                                <td class="text-right">
                                    {{ formatPercent(this.source.totalCoFundingReported/this.source.totalCoFunding) }}
                                </td>
                                <td class="text-right">
                                    {{ formatNumber(100 - (this.source.totalCoFundingReported / this.source.totalCoFunding * 100)) }}%
                                </td>
                            </tr>
                            <tr>
                                <td class="text-muted">2.1 Contributie in bani</td>
                                <td class="text-right text-muted">
                                    {{ formatPrice(this.source.totalCash) }}
                                </td>
                                <td class="text-right text-muted">
                                    {{ formatPercent(this.source.totalCash/this.source.totalCoFunding) }}
                                </td>
                                <td class="text-right">
                                    {{ formatPrice(this.source.totalCashReported) }}
                                </td>
                                <td class="text-right">
                                    {{ formatPercent(this.source.totalCashReported/this.source.totalCoFundingReported) }}
                                </td>
                                <td class="text-right">
                                    {{ formatPercent(this.source.totalCashReported/this.source.totalCash) }}
                                </td>
                                <td class="text-right">
                                    {{ formatNumber(100 - (this.source.totalCashReported / this.source.totalCash * 100)) }}%
                                </td>
                            </tr>
                            <tr>
                                <td class="text-muted">2.2 Contributie in natura</td>
                                <td class="text-right text-muted">
                                    {{ formatPrice(this.project.totalNature) }}
                                </td>
                                <td class="text-right text-muted">
                                    {{ formatPercent(this.project.totalNature/this.project.totalCoFunding) }}
                                </td>
                                <td class="text-right">
                                    {{ formatPrice(this.source.totalNatureReported) }}
                                </td>
                                <td class="text-right">
                                    {{ formatPercent(this.source.totalNatureReported/this.source.totalCoFundingReported) }}
                                </td>
                                <td class="text-right">
                                    {{ formatPercent(this.source.totalNatureReported/this.source.totalNature) }}
                                </td>
                                <td class="text-right">
                                    {{ formatNumber(100 - (this.source.totalNatureReported / this.source.totalNature * 100)) }}%
                                </td>
                            </tr>
                            <tr>
                                <td>Total costuri eligibile</td>
                                <td class="text-right">
                                    {{ formatPrice(this.source.totalEligible) }}
                                </td>
                                <td class="text-right">
                                    {{ formatPercent(this.source.totalEligible/this.source.totalEligible) }}
                                </td>
                                <td class="text-right">
                                    {{ formatPrice(this.source.totalProjectReported) }}
                                </td>
                                <td class="text-right">
                                    {{ formatPercent(this.source.totalProjectReported/this.source.totalProjectReported) }}
                                </td>
                                <td class="text-right">
                                    {{ formatPercent(this.source.totalProjectReported/this.source.totalEligible) }}
                                </td>
                                <td class="text-right">
                                    {{ formatNumber(100 - (this.source.totalProjectReported / this.source.totalEligible * 100)) }}%
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </div>
        <div class="row mt-5">
            <div class="col-sm-12">
                <h3>Centralizator Resurse Umane</h3>
            </div>
            <div class="col-6">
                <div class="alert alert-success">Selectează o activitate pentru a afișa detaliile!</div>
            </div>
            <div class="col-6 text-right">
<!--                <el-button type="secondary" round @click.prevent="openUrl('/reportings/audit/exportHrReport/' + reporting.id + '/' + project.id, true)" class="btn btn-sm btn-secondary ml-5">-->
<!--                    <i class="icofont-file-excel"></i> Export Excel-->
<!--                </el-button>-->
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <el-tabs name="hr" @tab-click="selectTab" v-loading="loading">
                    <el-tab-pane :label="'A'+activity.activity" :name="'hr' + activity.activity" v-for="activity in project.activities" :key="activity.id">
                        <template slot="label">
                            <el-tooltip position="top">
                                <div slot="content">{{ activity.name }}</div>
                                <span>A{{ activity.activity }}</span>
                            </el-tooltip>
                        </template>
                        <el-table size="mini"
                                  :data="hrReport[activity.id]"
                                  style="width: 100%">
                            <el-table-column align="center" label="Cod Promotor/Partener">
                                <template slot-scope="scope">
                            <span v-if="scope.row.partner_id !== project.promoter_id">
                               <span v-if="scope.row.partner_id">
                                    P{{ scope.row.partner_id }}
                               </span>
                               <span v-else>
                                   A
                               </span>
                            </span>
                                    <span v-else>
                                A
                            </span>
                                </template>
                            </el-table-column>
                            <el-table-column label="Activitatea" align="left">
                                <template slot-scope="scope" >
                                    <span >A{{scope.row.activity}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="Pozitia in cadrul proiectului" align="left">
                                <template slot-scope="scope" v-if="scope.row.position !== null">
                                    {{ scope.row.position }}
                                </template>
                            </el-table-column>
                            <el-table-column label="Nume si prenume" align="center"  header-align="center">
                                <template slot-scope="scope">
                                    <span>{{ scope.row.supplier_name }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="CNP" align="center" header-align="center">
                                <template slot-scope="scope">
                                    {{ scope.row.supplier }}
                                </template>
                            </el-table-column>
                            <el-table-column label="Ore neeligibile" align="center" header-align="center">
                                <template slot-scope="scope">
                                    {{ scope.row.month_hours }}
                                </template>
                            </el-table-column>
                            <el-table-column label="Cost neeligibil" align="right" header-align="center">
                                <template slot-scope="scope">
                                    {{ formatPrice(parseFloat(scope.row.total_spent_sum)) }}
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </div>
        <upload-drawer :user_data="user_id"></upload-drawer>
    </div>
</template>
<script>
    import mixin from './../../mixins/common';
    import {mapActions, mapGetters, mapMutations} from "vuex";
    import UploadDrawer from './../common/view-reporting-technical-drawer';
    export default {
        name: "reporting-audit-view",
        mixins:[mixin],
        components: {
            UploadDrawer,
        },
        props:{
            project: {
                required: true,
                type: Object
            },
            reporting: {
                required: true,
                type: Object
            },
            user_id: {
                required: false,
            }
        },
        data(){
            return{
                financialReportReported: [],
                financialIndirectReport: [],
                syntheticReport: [],
                financialPartners: [],
                source: [],
                hrReport: [],
                loading:false,
                reportTabs: '',
            }
        },
        computed:{
            ...mapGetters({
                showUploadDrawer: 'showUploadDrawer',
                riskDocuments: 'reportings/riskDocuments',
            }),
        },
        methods: {
            ...mapMutations({
                setShowUploadDrawer: 'setShowUploadDrawer',
            }),
            ...mapActions({
                getAuditFinancialReport: 'reportings/getAuditFinancialReport',
                getAuditIndirectReport: 'reportings/getAuditIndirectReport',
                getAuditSyntheticReport: 'reportings/getAuditSyntheticReport',
                getAuditPartnerReport: 'reportings/getAuditPartnerReport',
                getSources: 'reportings/getSources',
                getAuditHrReport: 'reportings/getAuditHrReport',
            }),
            viewDebitNote() {
                this.reporting.debit_note.forEach(doc => {
                    window.open('/getFile/' + doc.id, '_blank');
                })
            },
            viewDocuments() {
                this.setShowUploadDrawer({
                    visible: true,
                    model: 'App\\Models\\Reporting',
                    model_id: this.reporting.id,
                    category_id: 555,
                    acceptFiles: '.pdf',
                    multiple: false,
                    updateCommentFiles: 1,
                    canDelete: 1,
                    auxDocuments: 2,
                    title: 'Documente notă debit',
                    files: this.reporting.documents,
                });
            },
            selectTab(tab, event){
                this.loading = true;
                if (tab.name.substring(0, 8) === 'activity') {
                    this.getAuditFinancialReport({
                        project_id: this.project.id, reporting_id: this.reporting.id,
                    }).then((response) => {
                        this.financialReportReported = response;
                        this.loading = false;
                    });
                }
                if (tab.name === 'indirects') {
                    this.loading = true;
                    // go and bring the totals
                    this.getAuditIndirectReport({
                        project_id: this.project.id, reporting_id: this.reporting.id,
                    }).then((response) => {
                        this.financialIndirectReport = response;
                        this.loading = false;
                    });
                }
                if (tab.name === 'synthetic') {
                    this.loading = true;
                    // go and bring the totals
                    this.getAuditSyntheticReport({
                        project_id: this.project.id, reporting_id: this.reporting.id,
                    }).then((response) => {
                        this.syntheticReport = response;
                        this.loading = false;
                    });
                }
                if (tab.name === 'partners') {
                    this.loading = true;
                    // go and bring the totals
                    this.getAuditPartnerReport({
                        project_id: this.project.id, reporting_id: this.reporting.id,
                    }).then((response) => {
                        this.financialPartners = response;
                        this.loading = false;
                    });
                }
                if (tab.name === 'totals') {
                    this.loading = true;
                    // go and bring the totals
                    this.getSources({
                        project_id: this.project.id, reporting_id: this.reporting.id,
                    }).then((response) => {
                        this.source = response.data;
                        this.loading = false;
                    });
                }
                if (tab.name.substring(0, 2) === 'hr') {
                    this.getAuditHrReport({project_id: this.project.id, reporting_id: this.reporting.id})
                        .then(response => {
                            this.hrReport = response;
                            this.loading = false;
                        });
                }
            },
            getSummaries(param) {
                const { columns, data } = param;
                const sums = [];
                columns.forEach((column, index) => {
                    if (index === 0) {
                        sums[index] = 'Totaluri';
                        return;
                    }
                    // if(index === 1){
                    //     sums[index] = '';
                    //     return;
                    // }
                    const values = data.map(item => Number(item[column.property]));
                    if (!values.every(value => isNaN(value))) {
                        sums[index] = values.reduce((prev, curr) => {
                            const value = Number(curr);
                            if (!isNaN(value)) {
                                return prev + curr;
                            } else {
                                return prev;
                            }
                        }, 0);
                    } else {
                        sums[index] = ' - ';
                    }
                    if(index !== 0){
                        if (sums[index] !== ' - ') {
                            sums[index] =  this.formatPrice(parseFloat(sums[index]));
                        } else {
                            sums[index] = this.formatPrice(0);
                        }
                    }
                });
                return sums;
            },
        },
    }
</script>
<style scoped>
</style>
