<template>
    <el-form :model="reporting" :rules="rules"  ref="general" id="general" label-position="top" :status-icon="true" :inline-message="true">
        <div class="row">
            <div class="col-sm-6">
                <label>Numar contract finantare</label>
                <p>{{ project.funding_number }}</p>
            </div>
            <div class="col-sm-6">
                <label>Titlul proiectului</label>
                <p>{{ project.name }}</p>
            </div>
            <div class="col-sm-6">
                <label>Numele promotorului</label>
                <p>{{project.promoter.name}}</p>
            </div>
            <div class="col-sm-6">
                <div class="form-group">
                    <el-form-item label="Numele prestatorului de servicii de audit" prop="audit_agent">
                        <el-input v-model="reporting.audit_agent" type="text" placeholder="Introduceti numele" :max="90">
                        </el-input>
                    </el-form-item>
                </div>
            </div>
            <div class="col-sm-6">
                <label>Perioada auditata</label>
                <p>{{ formatUserDate(project.implementation_start_date) }} - {{ formatUserDate(project.implementation_end_date) }}</p>
            </div>
        </div>
    </el-form>
</template>

<script>
    import { mapGetters, mapActions, mapMutations } from 'vuex'
    import mixin from './../../mixins/common';
    import moment from 'moment';
    import VueScrollTo from 'vue-scrollto';
    import numeral from 'numeral';
    export default {
        name: "reporting-general-form",
        mixins:[mixin],
        data(){
            return {
                rules: {
                    audit_agent: [
                        { required: true, message: 'Introduceti numele persoanei ce face auditul.', trigger: 'blur' }
                    ],
                },
            }
        },
        computed:{
            ...mapGetters({
                reporting: 'reportings/reporting',
                project: 'project/project',
                validateGeneral: 'reportings/validateGeneral',
                validationErrorsExists: 'validationErrorsExists',
            }),
        },
        watch:{
            validateGeneral: function(){
                if(this.validateGeneral) {
                    this.setValidateGeneral(false);
                    this.submitForm();
                }
            },
        },
        mounted(){
            this.reporting.project_id = this.project.id;
            this.reporting.startDate = moment(this.project.project_start_date);
            this.reporting.endDate = moment(this.project.project_end_date);
        },
        methods:{
            ...mapMutations({
                setValidateGeneral: 'reportings/setValidateGeneral',
                setValidationErrorsExists: 'setValidationErrorsExists'
            }),
            submitForm() {
                this.$refs['general'].validate((valid) => {
                    if (valid) {
                        this.setValidationErrorsExists(false);
                        return true;
                    } else {
                        VueScrollTo.scrollTo('#general');
                        this.setValidationErrorsExists(true);
                        return false;
                    }
                })
            },
        }
    }
</script>

<style scoped>

</style>
