<template>
    <div v-if="project" id="indirect">
        <h3 class="mb-4 mt-3">
            Cheltuieli indirecte
        </h3>
        <div class="row">
            <div class="col-sm-12">
                <el-table :data="reporting.indirects" stripe fit highlight-current-row style="width:100%">
<!--                    <el-table-column type="expand" width="40">-->
<!--                        <template slot-scope="scope">-->
<!--                            bla-->
<!--                        </template>-->
<!--                    </el-table-column>-->
<!--                    <el-table-column label="Linie" prop="line" width="60" align="center"></el-table-column>-->
                    <el-table-column type="index"  width="60" align="center"></el-table-column>
                    <el-table-column label="Capitol buget" width="200">
                        <template slot-scope="scope">
                            <el-popover placement="top-start" title="Capitol buget" width="300" trigger="click" :content="scope.row.category.value">
                                <span  slot="reference">{{ scope.row.category.value }}</span>
<!--                                | truncate(40, '...')-->
                            </el-popover>
                        </template>
                    </el-table-column>
                    <el-table-column label="Aplicant/Partener" prop="partner">
                        <template slot-scope="scope">
                            {{ scope.row.partner.id !== project.promoter_id ? 'P' + scope.row.partner.id : 'A' }}
                        </template>
                    </el-table-column>
<!--                    <el-table-column label="Cod" prop="code"></el-table-column>-->
                    <el-table-column label="Linie buget" width="150">
                        <template slot-scope="scope">
                            <el-popover placement="top-start" title="Linie buget" width="300" trigger="click" :content="scope.row.budget.value">
                                <span  slot="reference">{{ scope.row.budget.value}}</span>
<!--                                | truncate(30, '...')-->
                            </el-popover>
                        </template>
                    </el-table-column>
                    <el-table-column label="Finantare">
                        <template slot-scope="scope">
                            {{ formatNumber(scope.row.amount_financing_percent,2) }}% ({{ formatPrice(parseFloat(scope.row.amount_financing)/parseFloat(scope.row.rate)) }})
                        </template>
                    </el-table-column>
                    <el-table-column label="Cofinantare">
                        <template slot-scope="scope">
                            {{ formatNumber(scope.row.amount_cofinancing_percent,2) }}% ({{ formatPrice(parseFloat(scope.row.amount_cofinancing)/parseFloat(scope.row.rate)) }})
                        </template>
                    </el-table-column>
                    <el-table-column label="Total Cost" align="right">
                        <template slot-scope="scope">
                            {{ formatPrice((parseFloat(scope.row.amount_financing) + parseFloat(scope.row.amount_cofinancing))/parseFloat(scope.row.rate)) }}
                        </template>
                    </el-table-column>
                    <el-table-column label="Dezv. Org.">
                        <template slot-scope="scope">
                            {{formatPrice(scope.row.dezv !== undefined ? scope.row.dezv : 0) }}
                        </template>
                    </el-table-column>
                    <el-table-column label="Actiuni" width="130px" align="center">
                        <template slot-scope="scope">
                            <el-button size="mini" type="secondary" icon="el-icon-edit"
                                       @click="handleSelectRow(scope.$index, scope.row)"></el-button>
                            <el-button size="mini" type="danger" icon="el-icon-delete"
                                       @click="handleDeleteRow(scope.$index, scope.row)"></el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <el-form :model="form" :id="'reportingIndirectForm'" :validate-on-rule-change="false"
                 v-loading="loading"  :rules="rules" :ref="'reportingIndirectForm'"
                 label-position="top" :status-icon="true" :inline-message="true">
            <div class="row">
                    <div class="col-sm-12">
                        <h4 class="mb-4 mt-5">Adauga / Modifica linie cheltuieli</h4>
                    </div>
                    <div class="col-sm-12 form-group">
                        <el-form-item label="Capitol buget" prop="category">
                            <el-select v-model="form.category" placeholder="Selecteaza" style="width:100%" value-key="id" @change="updatePosition">
                                <el-option v-for="item in (indirectCategories.values ?? costCategories.values)"
                                           :key="item.id" :label="item.value"  :value="item">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                    <div class="col-sm-6 form-group">
                        <el-form-item label="Cheltuiala se aloca la:" prop="partner">
                            <el-select v-model="form.partner" clearable placeholder="Selecteaza" style="width:100%" value-key="id">
                                <el-option key="0" label="Aplicant" :value="{ id: this.project.promoter_id }"></el-option>
                                <el-option v-for="item in project.partners"
                                           :key="item.id" :label="item.name" :value="item">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                    <div class="col-sm-6 form-group">
                        <el-form-item label="Linia de buget" prop="budget">
                            <el-select v-model="form.budget" placeholder="Selecteaza" style="width:100%" value-key="id" @change="updateLine">
                                <el-option v-for="item in budgetLines.values"
                                           :key="item.id" :label="item.value"  :value="item">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                    <div class="col-sm-6 form-group" v-if="showPosition">
                        <el-form-item label="Pozitia in cadrul proiectului" prop="position"
                                      :rules="[{ required: true, message: 'Acest camp este obligatoriu', trigger: 'blur' }]">
                            <el-select v-model="form.position" placeholder="Selecteaza" style="width:100%" value-key="id" @change="updateSupplier">
                                <el-option v-for="item in project.employees" v-if="!showVolunteer && item.is_volunteer !== 1"
                                           :key="item.id" :label="item.position + (item.first_name ? ' - ' + item.first_name + ' ' + item.last_name: '')" :value="item">
                                </el-option>
                                <el-option v-for="item in project.employees" v-if="showVolunteer && item.is_volunteer === 1"
                                           :key="item.id" :label="item.position + (item.first_name ? ' - ' + item.first_name + ' ' + item.last_name: '')" :value="item">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                    <div class="col-sm-3 form-group" v-if="showPosition">
                        <el-form-item label="Ore neelegibile" prop="month_hours">
                            <el-input v-model="form.month_hours" placeholder="Număr ore" type="number" clearable></el-input>
                        </el-form-item>
                    </div>
                </div>
            <div class="row">
                <div class="col-sm-3 form-group">
                    <el-form-item :label="(showPosition ? 'Cheltuieli din FINANTARE NEELIGIBILE' : 'Cheltuieli din FINANTARE NEELIGIBILE')" prop="total_financing_spent">
                        <el-input v-model="form.amount_financing" placeholder="0" type="text" show-word-limit
                                  @focus="$event.target.select()">
                            <template slot="append">{{form.currency}}</template>
                        </el-input>
                    </el-form-item>
                </div>
                <div class="col-sm-3 form-group">
                    <el-form-item label="Cheltuieli din COFINANTARE NEELIGIBILE" prop="total_cofinancing_spent">
                        <el-input v-model="form.amount_cofinancing" placeholder="0" type="text" show-word-limit
                                  @focus="$event.target.select()">
                            <template slot="append">{{form.currency}}</template>
                        </el-input>
                    </el-form-item>
                </div>
                <div class="col-sm-3 form-group">
                    <el-form-item label="COST TOTAL NEELIGIBIL">
                        <el-input :model="form.total_spent" :value="formatPrice(parseFloat(form.amount_financing) + parseFloat(form.amount_cofinancing))" type="text" disabled style="text-align:right">
                            <template slot="append">€</template>
                        </el-input>
                    </el-form-item>
                </div>
                <div class="col-sm-3 form-group">
                    <el-form-item label="Partea costului total NEELIGIBIL pe componenta de dezvoltare organizationala" class="form-group">
                        <el-input v-model="form.dezv" placeholder="0" @focus="$event.target.select()">
                            <template slot="append">€</template>
                        </el-input>
                    </el-form-item>
                </div>
                <div class="col-sm-12 form-group">
                    <el-form-item label="Motivarea considerarii cheltuielilor ca fiind NEELIGIBILE" prop="description">
                        <el-input v-model="form.description" placeholder="Introduceti motivarea" type="textarea" maxlength="4000" show-word-limit></el-input>
                    </el-form-item>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 text-right mb-5">
                    <el-button @click.prevent="cancelRecord">Anulează, m-am răzgândit</el-button>
                    <el-button @click.prevent="clear">Goleste formularul</el-button>
                    <el-button @click.prevent="addRecord" type="secondary">Aplică modificările</el-button>
                </div>
            </div>
        </el-form>
    </div>
</template>

<script>
    import { mapGetters, mapActions, mapMutations } from 'vuex'
    import mixin from './../../mixins/common';
    import moment from 'moment';
    import VueScrollTo from 'vue-scrollto';
    export default {
        name: "reporting-indirect-form",
        mixins:[mixin],
        data(){
            return {
                indirects:[],
                csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
                showPosition: false,
                showVolunteer: false,
                loading:false,
                form:{
                    category: {
                        id: 106,
                        value: '2. COSTURI INDIRECTE'
                    },
                    budget: {
                        id: 187,
                        value: '2.1. Costuri indirecte'
                    },
                    dezv: 0,
                    amount_cost: 0,
                    amount_financing: 0,
                    amount_financing_percent: 0,
                    amount_cofinancing: 0,
                    amount_cofinancing_percent: 0,
                    total_financing_spent: 0,
                    total_cofinancing_spent: 0,
                    total_spent: 0,
                },
                minDate: new Date(),
                maxDate: new Date(),
                periodOptions: {
                    disabledDate: this.disabledDate
                },
                currentRate: 1,
                drawer: false,
                rules: {
                    partner: [
                        {required: true, message: 'Aplicantul sau partenerul este obligatoriu', trigger: 'blur'},
                    ],
                    supplier: [
                        {required: true, message: 'CUI-ul/CNP-ul este obligatoriu', trigger: 'blur'},
                    ],
                    supplier_name: [
                        {required: true, message: 'Denumirea/numele este obligatoriu', trigger: 'blur'},
                    ],
                    description: [
                        {required: true, message: 'Motivarea este obligatorie', trigger: 'blur'},
                    ],
                    invoice_no: [
                        {required: true, message: 'Numarul documentului este obligatoriu', trigger: 'blur'},
                    ],
                    invoice_date: [
                        {required: true, message: 'Data documentului este obligatoriu', trigger: 'blur'},
                    ],
                    receipt_no: [
                        {required: true, message: 'Numarul documentului este obligatoriu', trigger: 'blur'},
                    ],
                    receipt_date: [
                        {required: true, message: 'Data documentului este obligatoriu', trigger: 'blur'},
                    ],
                    amount_cost: [
                        {required: true, message: 'Valoarea este obligatorie', trigger: 'blur'},
                    ],
                    amount_financing_percent: [
                        {required: true, message: 'Valoarea este obligatorie', trigger: 'blur'},
                    ],
                    amount_cofinancing_percent: [
                        {required: true, message: 'Valoarea este obligatorie', trigger: 'blur'},
                    ],
                },
            }
        },
        computed:{
            ...mapGetters({
                // rates: 'rates',
                dayRate: 'dayRate',
                indirectCategories: 'project/indirectCategories',
                budgetLines: 'project/budgetLines',
                getProject: 'project/project',
                validateIndirect: 'reportings/validateIndirect',
                validationErrorsExists: 'validationErrorsExists',
                reporting: 'reportings/reporting',
            }),
            project(){
                return this.getProject;
            },
            // reporting(){
            //     // console.log(this.$store.state.reportings.reporting);
            //     // console.log(this.reporting());
            //     return this.getReporting;
            // },
        },
        watch:{
            validateIndirect: function(){
                if(this.validateIndirect) {
                    this.submitForm();
                }
            },
            reporting: function(newVal){
                if (newVal.indirects) {
                    this.indirects = newVal.indirects;
                    // this.form.line = newVal.indirects.length + 1;
                    this.form.line =  this.indirects.length > 0 ? this.indirects.reduce((a,b) => Number(a.line) > Number(b.line) ? a.line : b.line,1) + 1 : 1;
                }

                if (this.reporting.type_report === 2) {
                    this.minDate = moment(this.project.implementation_start_date);
                    this.maxDate = moment(this.project.implementation_end_date).add(30,'days');
                } else {
                    this.minDate = moment(newVal.start_date);
                    this.maxDate = moment(newVal.end_date);
                }
            }
        },
        mounted(){
            // this.clear();
            // if(this.reporting.type_report === 2)
            // {
            //     this.minDate = moment(this.project.implementation_start_date);
            //     this.maxDate = moment(this.project.implementation_end_date).add(30,'days');
            // }
            // else
            // {
            //     this.minDate = moment(this.reporting.start_date);
            //     this.maxDate = moment(this.reporting.end_date);
            // }

            // this.minDateRep = moment(this.reporting.start_date);
            // this.maxDateRep = moment(this.reporting.end_date);

            // this.contractMinDate = moment(this.project.implementation_start_date);
            // this.contractMaxDate = moment(this.project.implementation_end_date);

        },
        methods:{
            ...mapActions({
                getBudgetLines: 'project/getBudgetLines',
                searchAnafCompany: 'searchAnafCompany',
                getDateRates: 'getDateRates',
                saveAuditIndirectLine: 'reportings/saveAuditIndirectLine',
                removeIndirectLine: 'reportings/removeIndirectLine',
            }),
            ...mapMutations({
                setValidateIndirect: 'reportings/setValidateIndirect',
                setValidationErrorsExists: 'setValidationErrorsExists',
            }),
            disabledDate(date) {
                if(moment(date) >= this.minDate && moment(date) <= this.maxDate){
                    return false;
                }else{
                    return true;
                }
            },
            getRate(){
                if(this.form.invoice_date && this.form.currency){
                    this.getDateRates([moment(new Date(this.form.invoice_date)).format('YYYY-MM-DD'),this.form.currency])
                        .then((result) => {
                            this.currentRate = result.value;
                        });
                }
            },
            recalculateTotal() {
                this.form.amount_financing = ((this.form.amount_financing_percent/100)*this.form.amount_cost).toFixed(2);
                this.form.amount_cofinancing = ((this.form.amount_cofinancing_percent/100)*this.form.amount_cost).toFixed(2);
            },
            getRate2(){
                this.form.amount_financing = ((this.form.amount_financing_percent/100)*this.form.amount_cost).toFixed(2);
            },
            getRate3() {
                this.form.amount_cofinancing = ((this.form.amount_cofinancing_percent/100)*this.form.amount_cost).toFixed(2);
            },
            handleSelectCui(item){
                this.form.supplier_name = item.label;
            },
            updatePosition(){
                this.showPosition = this.form.category.id === 99;
                this.getBudgetLines(this.form.category.id);
                // this.getUnits(this.form.category.id === 99 ? 99 : 0);
            },
            updateSupplier(){
                this.form.supplier = this.form.position.first_name + ' ' + this.form.position.last_name;
            },
            updateLine(){
                this.showPosition = this.form.category.id === 99;
                if(this.form.budget.id === 108) {
                    this.showVolunteer = true;
                }else{
                    this.showVolunteer = false;
                }
            },
            getCompanies(query, cb) {
                if (query !== '') {
                    // setTimeout(() => {
                    this.searchAnafCompany(query).then(result => {
                       cb(result);
                    });
                    // }, 200);
                }
            },
            cancelRecord(){
                this.clear();
                VueScrollTo.scrollTo('#indirect');
            },
            okIndirects2(){
                let total = 0;
                let totalIndirects = 0;
                if(this.reporting.activities && this.reporting.activities.length > 0) {
                    this.reporting.activities.forEach((element) => {
                        if (element.budget_id === 107) {
                            total += (parseFloat(element.amount_financing) + parseFloat(element.amount_cofinancing)) / parseFloat(element.rate);
                        }
                    });
                    total = parseFloat(total * this.project.indirect_budget);
                    if (this.reporting.indirects !== undefined) {
                        this.reporting.indirects.forEach((element) => {
                            totalIndirects += (parseFloat(element.amount_financing) + parseFloat(element.amount_cofinancing)) / parseFloat(element.rate);
                        });
                    }
                    totalIndirects += parseFloat(this.form.amount_financing);
                }

                return total >= totalIndirects;
                //return true;
            },
            addRecord(){
                //if(!this.okIndirects2()) return this.$notify({ type: 'warning', title: 'Buget', message: 'Valoarea costurilor indirecte introduse depaseste valorea proiectului! Modifica valorile introduse.'});
                this.$refs['reportingIndirectForm'].validate((valid) => {
                    if (valid) {
                        this.loading = false;
                        this.form.rate = this.currentRate;
                        this.form.project_id = this.reporting.project_id;
                        // console.log(this.form.amount_cofinancing);
                        // console.log(this.form.amount_financing);
                        // console.log(this.form.amount_cost);
                        // if ((parseFloat(this.form.amount_cofinancing) + parseFloat(this.form.amount_financing)) > parseFloat(this.form.amount_cost)) {
                        //     this.$notify({ type: 'error', title: 'Indirecte', message: 'Finantare + cofinantare trebuie sa fie mai mic de cost total'});
                        //     //this.clear();
                        //     return false;
                        // }
                        let totalBudget = 0;
                        this.reporting.indirects.forEach(function(e){
                            totalBudget += parseFloat(e.amount_cost);
                        });
                        let value = totalBudget * this.currentRate;
                        if (parseFloat(value) > parseFloat(this.reporting.indirectMaxValue)) {
                            this.$notify({ type: 'error', title: 'Indirecte', message: 'Valoarea introdusa depaseste valoarea maxima permisa conform procentului din proiect'});
                            return false;
                        }

                        this.form.line = this.indirects.length > 0 ? this.indirects.reduce((a,b) => Number(a.line) > Number(b.line) ? a.line : b.line,1) + 1 : 1;
                        this.form.reporting_id = this.reporting.id;
                        // if (parseInt(this.form.amount_financing) + parseInt(this.form.amount_cofinancing) < 100) {
                        //     this.$notify({ type: 'error', title: 'Indirecte', message: 'Procent finantare + procent cofinantare trebuie sa fie mai mic de 100%'});
                        //     return false;
                        // }
                        if(this.form.line > this.indirects.length) {
                            this.indirects.push(this.form);
                        }else{
                            this.indirects[this.form.line - 1] = this.form
                        }
                        this.saveAuditIndirectLine(this.form).then(() => {
                            this.$notify({ type: 'success', title: 'Cheltuieli', message: 'Înregistrarea a fost adăugată cu succes!'});
                            this.clear();
                            VueScrollTo.scrollTo('#reportingIndirectForm');
                            this.loading = false;
                        })

                        this.setValidateIndirect(false);
                        VueScrollTo.scrollTo('#indirect');
                        this.clear();
                        return true;
                    } else {
                        VueScrollTo.scrollTo('#reportingIndirectForm');
                        return false;
                    }
                });
            },
            handleSelectRow(line, row){
                this.form = row;
                this.form.line = line;
                if(!row.partner){
                    this.form.partner = '0';
                }
                this.updatePosition();
                this.getRate();
                VueScrollTo.scrollTo('#reportingIndirectForm');
            },
            clear(){
                this.form =  {
                    // line: this.indirects.length + 1,
                    line: this.indirects.length > 0 ? this.indirects.reduce((a,b) => Number(a.line) > Number(b.line) ? a.line : b.line,1) + 1 : 1,
                    currency: 'EUR',
                    category: {
                        id: 106,
                        value: '2. COSTURI INDIRECTE'
                    },
                    budget: {
                        id: 187,
                        value: '2.1. Costuri indirecte'
                    },
                    supplier:'',
                    supplier_name:'',
                    dezv: 0,
                    amount_cost: 0,
                    amount_financing: 0,
                    amount_financing_percent: 0,
                    amount_cofinancing: 0,
                    amount_cofinancing_percent: 0,
                    total_financing_spent: 0,
                    total_cofinancing_spent: 0,
                    total_spent: 0,
                };
                this.updatePosition();
            },
            handleDeleteRow(line, row){
                this.$confirm('Esti sigur(a) ca vrei sa stergi aceasta inregistrare? Acest proces este ireversibil!')
                    .then(_ => {
                        if (line !== -1) {
                            if(row.id > 0) {
                                this.removeIndirectLine({
                                    pid: row.project_id,
                                    rid: row.reporting_id,
                                    line: row.id
                                });
                                this.indirects.splice(line, 1);
                            }else {
                                this.indirects.splice(line, 1);
                            }
                            this.$notify({ type: 'success', title: 'Linie buget', message: 'Inregistrarea a fost stearsa cu succes!'});
                            this.clear();
                        }
                    }).catch(err => {
                        console.log(err)
                    });
            },
            submitForm() {
                // if (this.activity.length > 0) {
                    this.reporting.indirects = this.indirects;
                    this.setValidateIndirect(false);
                    this.setValidationErrorsExists(false);
                    VueScrollTo.scrollTo('#indirect');
                    return true;
                // } else {
                //     this.setValidationErrorsExists(true);
                //     this.$notify({ type: 'warning', title: 'Buget', message: 'Trebuie sa adaugati cel putin o linie de cheltuieli!'});
                //     return false;
                // }
            },
        }
    }
</script>

<style scoped>
    .el-popover__reference{
        cursor: help;
    }
</style>
