import Vue from 'vue'
// import './../../../public/css/isaura.css'
// import {Autocomplete, Button, Collapse, CollapseItem, Checkbox, DatePicker, Form, FormItem, Icon, Input, Loading, MessageBox, Option, Popover, Select, Table, TableColumn, Tabs, TabPane} from 'element-ui';
import lang from 'element-ui/lib/locale/lang/ro'
import locale from 'element-ui/lib/locale'
locale.use(lang);
// Vue.use(Loading.directive);

/** Vue Filters Start */
Vue.filter('truncate', function (text, length, suffix) {
    if (text && text.length > length) {
        return text.substring(0, length) + suffix;
    } else {
        return text;
    }
});


//
// Vue.component(Autocomplete.name, Autocomplete);
// Vue.component(Button.name, Button);
// Vue.component(Collapse.name, Collapse);
// Vue.component(CollapseItem.name, CollapseItem);
// Vue.component(Checkbox.name, Checkbox);
// Vue.component(DatePicker.name, DatePicker);
// Vue.component(Form.name, Form);
// Vue.component(FormItem.name, FormItem);
// Vue.component(Icon.name, Icon);
// Vue.component(Input.name, Input);
// Vue.component(MessageBox .name, MessageBox);
// Vue.component(Option.name, Option);
// Vue.component(Popover.name, Popover);
// Vue.component(Select.name, Select);
// Vue.component(Table.name, Table);
// Vue.component(TableColumn.name, TableColumn);
// Vue.component(Tabs.name, Tabs);
// Vue.component(TabPane.name, TabPane);
// Vue.component(Option.name+, Option);

Vue.component('upload-drawer-xml', require('./common/upload-drawer-xml').default);
Vue.component('upload-drawer-simple', require('./common/upload-drawer-simple').default);
Vue.component('catalog-edit', require('./catalog/edit').default);

Vue.component('common-send-email', require('./common/send-email').default);
Vue.component('common-create-template', require('./common/create-template').default);
Vue.component('template-edit', require('./common/edit-template').default);

// promoters
Vue.component('promoter-page-list', require('./promoter/page-list').default);
Vue.component('promoter-page-view', require('./promoter/page-view').default);
Vue.component('promoter-page-wizard', require('./promoter/page-wizard').default);
Vue.component('promoter-page-account', require('./promoter/page-account').default);
Vue.component('form-account', require('./promoter/form-account').default);

// projects
Vue.component('project-page-list', require('./project/page-list').default);
Vue.component('project-page-view', require('./project/page-view').default);
Vue.component('project-page-wizard-technical', require('./project/page-wizard-technical').default);
Vue.component('project-page-wizard-financial', require('./project/page-wizard-financial').default);
Vue.component('project-page-account', require('./project/page-account').default);

//reportings
Vue.component('pane-reporting-list', require('./reportings/pane-reporting-list').default);
Vue.component('page-reporting-view', require('./reportings/page-view').default);
Vue.component('page-reporting-risk', require('./reportings/pane-risk').default);
Vue.component('page-reporting-view-technical', require('./reportings/page-view-technical').default);
Vue.component('page-view-financial-report', require('./reportings/page-view-financial-report').default);
Vue.component('page-view-hr-report', require('./reportings/page-view-hr-report').default);
Vue.component('page-reporting-verify', require('./reportings/page-verify').default);
Vue.component('page-reporting-verify-technical', require('./reportings/page-verify-technical').default);
Vue.component('page-reporting-audit-view', require('./reportings-audit/page-view').default);

// Notifications
Vue.component('notification-page-list', require('./notification/page-list').default);
Vue.component('notification-form', require('./notification/form-notification').default);
Vue.component('notification-page-wizard', require('./notification/page-notification-wizard').default);
Vue.component('notification-page-view', require('./notification/page-view').default);
Vue.component('notification-page-verify', require('./notification/page-verify').default);

//Reportings audit
Vue.component('reporting-page-financial-wizard', require('./reportings-audit/page-financial-wizard').default);


// reports
Vue.component('report-page-view', require('./report/page-view').default);

export default {
    Vue
}
