<template>
    <div>
        <div class="row">
            <div class="col-sm-22">
                <h3>
                    Centralizator Resurse Umane
                </h3>
            </div>
        </div>
        <div class="row">
            <div class="col-6">
                <div class="alert alert-success">Selecteaza o activitate pentru a incarca detaliile!</div>
            </div>
            <div class="col-6 text-right">
                <el-button type="secondary" round @click.prevent="openUrl('/reportings/audit/exportHrReport/' + reporting.id, true)" class="btn btn-sm btn-secondary ml-5">
                    <i class="icofont-file-excel"></i> Export Excel
                </el-button>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <el-tabs name="hr" @tab-click="handleHrTab">
                    <el-tab-pane :label="'A'+activity.activity" :name="'A'+activity.activity" v-for="activity in project.activities" :key="activity.id">
                    <template slot="label">
                        <el-tooltip position="top">
                            <div slot="content">{{ activity.name }}</div>
                            <span>A{{ activity.activity }}</span>
                        </el-tooltip>
                    </template>
                    <el-table size="mini"
                              :data="hrReport[activity.id]"
                              style="width: 100%">
                        <el-table-column align="center" label="Cod Promotor/Partener">
                            <template slot-scope="scope">
                                <span v-if="scope.row.partner_id !== project.promoter_id">
                                   <span v-if="scope.row.partner_id">
                                        P{{ scope.row.partner_id }}
                                   </span>
                                   <span v-else>
                                       A
                                   </span>
                                </span>
                                <span v-else>
                                    A
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column label="Activitatea" align="left">
                            <template slot-scope="scope" >
                                <span >A{{scope.row.activity}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="Pozitia in cadrul proiectului" align="left">
                            <template slot-scope="scope" v-if="scope.row.position !== null">
                                {{ scope.row.position }}
                            </template>
                        </el-table-column>
                        <el-table-column label="Nume si prenume" align="left"  header-align="center">
                            <template slot-scope="scope">
                                <span v-if="scope.row.supplier_name">{{ scope.row.supplier_name }}</span>
                                <span v-else-if="scope.row.first_name">{{ scope.row.first_name }} {{ scope.row.last_name }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="CNP" align="center" header-align="center">
                            <template slot-scope="scope">
                                {{ scope.row.supplier }}
                            </template>
                        </el-table-column>
                        <el-table-column label="Ore neeligibile" align="center" header-align="center">
                            <template slot-scope="scope">
                                {{ scope.row.month_hours }}
                            </template>
                        </el-table-column>
                        <el-table-column label="Cost neeligibil" align="right" header-align="center">
                            <template slot-scope="scope">
                                {{ formatPrice(parseFloat(scope.row.total_spent_sum)) }}
                            </template>
                        </el-table-column>
                    </el-table>
                </el-tab-pane>
                </el-tabs>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapGetters, mapActions, mapMutations } from 'vuex'
    import mixin from './../../mixins/common';
    import showInformationDrawer from "../common/modify-information-drawer";
    import UploadDrawer from "../common/upload-reporting-drawer";

    export default {
        name: "reporting-hr-form",
        mixins:[mixin],
        components: {
            UploadDrawer,
        },
        data() {
            return {
                hrReport: [],
            }
        },
        computed:{
            ...mapGetters({
                showUploadDrawer: 'showUploadDrawer',
                project: 'project/project',
                reporting: 'reportings/reporting',
            }),
        },
        watch:{
            showUploadDrawer:function(){
                if(this.showUploadDrawer.visible === false){

                }
                return this.showUploadDrawer;
            },
        },
        methods:{
            ...mapActions({
                getAuditHrReport: 'reportings/getAuditHrReport',
            }),
            ...mapMutations({
                setShowUploadDrawer: 'setShowUploadDrawer',
            }),
            openDrawer(object){
                this.setShowUploadDrawer({
                    visible: true,
                    model: 'App\\Models\\ReportingValidationActivity',
                    model_id: object.id,
                    category_id: 0,
                    acceptFiles: '.pdf,.docx,.doc', //.docx,.doc,
                    multiple: true,
                    title: 'Încărcare document',
                    type: 1,
                    object: object,
                });
            },
            handleHrTab(){
                this.loading = true;
                this.getAuditHrReport({project_id: this.project.id, reporting_id: this.reporting.id})
                    .then(response => {
                        this.hrReport = response;
                        this.loading = false;
                    });
            },
        },

    }
</script>

<style scoped>

</style>
