import Vue from 'vue';

export const setProjects = (state, payload) => {state.projects = payload };
export const setProject = (state, payload) => {state.project = payload };
export const setLocations = (state, payload) => { state.locations = payload };
export const setAppeals = (state, payload) => { state.appeals = payload };
export const setRounds = (state, payload) => { state.rounds = payload };
export const setGrants = (state, payload) => { state.grants = payload };
export const setMedium = (state, payload) => { state.medium = payload };
export const setFraming = (state, payload) => { state.framing = payload };
export const setPriorities = (state, payload) => { state.priorities = payload };
export const setPolicies = (state, payload) => { state.policies = payload };
export const setCompanyTypes = (state, payload) => { state.companyTypes = payload };
export const setDocumentCategories = (state, payload) => { state.documentCategories = payload };
export const setWorkloads = (state, payload) => { state.workloads = payload };
export const setCostCategories = (state, payload) => { state.costCategories = payload };
export const setIndirectCategories = (state, payload) => { state.indirectCategories = payload };
export const setBudgetLines = (state, payload) => { state.budgetLines = payload };
export const setUnits = (state, payload) => { state.units = payload };
export const setIndirects = (state, payload) => { state.indirects = payload };
export const setOutcomes = (state, payload) => { state.outcomes = payload };
export const setOutputs = (state, payload) => { state.outputs = payload };
export const setOutcomeIndicators = (state, payload) => { state.outcomeIndicators = payload };
export const setOutputIndicators = (state, payload) => { state.outputIndicators = payload };
export const setUsers = (state, payload) => { state.users = payload };

export const setValidateDetails = (state, validate) => { state.validateDetails = validate };
export const setValidateDescription = (state, validate) => { state.validateDescription = validate };
export const setValidateActivities = (state, validate) => { state.validateActivities = validate };
export const setValidateDocuments = (state, validate) => { state.validateDocuments = validate };
export const setValidatePartners = (state, validate) => { state.validatePartners = validate };
export const setValidateObjectives = (state, validate) => { state.validateObjectives = validate };
export const setValidateIndicators = (state, validate) => { state.validateIndicators = validate };
export const setValidateCalendar = (state, validate) => { state.validateCalendar = validate };
export const setValidateValidation = (state, validate) => { state.validateValidation = validate };
export const setValidateTeam = (state, validate) => { state.validateTeam = validate };
export const setValidateActivity = (state, payload) => {
    Vue.set(state.validateActivity, payload.index, payload.value);
};
export const setValidateIndirects = (state, validate) => { state.validateIndirects = validate };
export const setValidateFinancing = (state, validate) => { state.validateFinancing = validate };

