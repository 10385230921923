import Vue from "vue";
import {validateDocuments} from './getters';
export const setErrors = (state, payload) => { state.errors = payload };
export const setPromoters = (state, promoters) => { state.promoters = promoters };
export const setValidatePromoter = (state, validatePromoter) => { state.validatePromoter = validatePromoter };
export const setPromoterValidated = (state, promoterValidated) => { state.promoterValidated = promoterValidated };
export const setValidatePromoterAccount = (state, validatePromoterAccount) => { state.validatePromoterAccount = validatePromoterAccount };
export const setPromoterAccountValidated = (state, promoterAccountValidated) => { state.promoterAccountValidated = promoterAccountValidated };
// export const setFinancialReport = (state, payload) => { state.financialReport = payload };
// export const setFinancialIndirectReport = (state, payload) => { state.financialIndirectReport = payload };
export const setProject = (state, payload) => { state.project = payload };

export const setReporting = (state, payload) => { state.reporting = payload };
export const setReportingActivities = (state, payload) => { state.reporting = payload };
export const setValidationState = (state, payload) => { state.validationState = payload };
export const setFinancialReport = (state, payload) => { state.financialReport = payload };
export const setFinancialReportNow = (state, payload) => { state.financialReportNow = payload };
export const setHrReport = (state, payload) => { state.hrReport = payload };
export const setSyntheticReport = (state, payload) => { state.syntheticReport = payload };
export const setSyntheticReportNow = (state, payload) => { state.syntheticReportNow = payload };
export const setFinancialPartners = (state, payload) => { state.financialPartners = payload };
export const setFinancialPartnersNow = (state, payload) => { state.financialPartnersNow = payload };
export const setFinancialIndirectReport = (state, payload) => { state.financialIndirectReport = payload };
export const setFinancialIndirectReportNow = (state, payload) => { state.financialIndirectReportNow = payload };
export const setRiskDocuments = (state, payload) => { state.riskDocuments = payload };
export const setReportingDocuments = (state, payload) => { state.reporting.documents = payload };
export const setReportingIndirects = (state, payload) => { state.reporting.indirects = payload };
export const setAuditIndirects = (state, payload) => { state.reporting.indirects = payload };
export const setAuditDocuments = (state, payload) => { state.reporting.documents = payload };
export const setValidateGeneral = (state, payload) => { state.validateGeneral = payload };
export const setValidateActivity = (state, payload) => {
    Vue.set(state.validateActivity, payload.index, payload.value);
};
export const setValidateIndirect = (state, payload) => { state.validateIndirect = payload };
export const setValidateFinal = (state, payload) => { state.validateFinal = payload };
export const setValidateReport = (state, payload) => { state.validateReport = payload };
export const setValidateDocuments = (state, payload) => { state.validateDocuments = payload };
